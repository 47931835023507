<template>
  <a-upload
    name="file"
    :showUploadList="false"
    :customRequest="getData"
  >
    <a-button :disabled="disabled"> {{ title }} </a-button>
  </a-upload>
</template>
<script>
import { toRefs } from 'vue'
export default {
  props: {
    title: {
      type: String,
      default: '选择文件'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['getResult'],
  setup (props, { emit }) {
    // 最终参数
    let sections = []
    const state = {
      currentDir: null,
      path: []
    }
    state.currentDir = sections

    const findDir = (arr, dirLevel = 0) => {
      if (dirLevel > state.path.length) {
        return
      }
      if (state.path.length === 0) {
        state.currentDir = sections
        return
      }
      if (!arr) {
        arr = sections
      }
      arr = arr.filter(i => i.children)
      const index = state.path[dirLevel]
      for (const k in arr) {
        if (parseInt(k) === index) {
          state.currentDir = arr[k].children
          findDir(state.currentDir, ++dirLevel)
          return
        }
      }
    }

    const generateItem = (line) => {
      const lastIndex = line.lastIndexOf('*')
      const name = line.slice(lastIndex + 1)
      const prefix = line.slice(0, lastIndex + 1)

      if (!name) {
        return
      }
      if (prefix) {
        // 目录
        if (prefix.replaceAll('*', '').length > 0) {
          throw new Error('输入格式错误')
        }
        const currentLevel = prefix.length
        const beforeLevel = state.path.length + 1
        // 下一级 前某级
        if (currentLevel === beforeLevel) {
          state.currentDir.push({
            name,
            type: 0,
            children: []
          })
          state.path.push(0)
          findDir()
        } else if (currentLevel < beforeLevel) {
          state.path = state.path.slice(0, currentLevel)
          const tmpIndex = state.path.pop()
          findDir()
          state.currentDir.push({
            name,
            type: 0,
            children: []
          })
          state.path.push(tmpIndex + 1)
          findDir()
        } else {
          throw new Error('格式错误')
        }
      } else {
        // 课时
        state.currentDir.push({
          name,
          type: 1,
          resource_id: 0
        })
      }
    }

    const getData = info => {
      const reader = new FileReader()
      reader.readAsText(info.file)
      reader.onload = () => {
        try {
          const lines = reader.result.split(/\r\n|\n/g)
          for (const line of lines) {
            generateItem(line)
          }
          // console.log(777, sections)
          emit('getResult', { sections })
          resetData()
        } catch (e) {
          emit('getResult', { errorCode: 1, message: e.message })
          console.error(e)
        }
      }
    }

    const resetData = () => {
      sections = []
      state.currentDir = sections
      state.path = []
    }

    const getData2 = info => {
      const res = { sections: [] }
      class Probe { // 用于支持回退
        constructor (deep, obj, fatherProbe = null) {
          this.deep = deep
          this.obj = obj
          this.fatherProbe = fatherProbe
        }
      }

      const addNode = (probe, node) => {
        if (probe.fatherProbe.obj.sections) {
          probe.fatherProbe.obj.sections.push(node)
        } else {
          probe.fatherProbe.obj.children.push(node)
        }
      }

      const getDepth = str => {
        let index = 0
        let sum = 0
        while (str[index] === '*') {
          index++
          sum++
        }
        return sum || 99
      }

      const reader = new FileReader()
      reader.readAsText(info.file) // 异步
      reader.onload = () => {
        try {
          const lines = reader.result.split(/\r\n|\n/g)
          let deep
          let probe = new Probe(0, res)
          for (const line of lines) {
            if (!line) { // 空行
              continue
            }
            deep = getDepth(line)
            const node = deep === 99 ? {
              name: line.slice(0),
              type: 1,
              resource_id: 1
            } : {
              name: line.slice(deep),
              type: 0,
              children: []
            }
            const newProbe = new Probe(deep, node)

            if (deep > probe.deep) { // 往低级走
              newProbe.fatherProbe = probe
              addNode(newProbe, node)
              probe = newProbe
            } else if (deep === probe.deep) {
              addNode(probe, node)
            } else { // 回退到同级
              while (probe.deep !== deep) {
                probe = probe.fatherProbe
              }
              addNode(probe, node)
              newProbe.fatherProbe = probe.fatherProbe
              probe = newProbe
            }
          }
          emit('getResult', res)
        // resetData()
        } catch (e) {
          emit('getResult', { errorCode: 1, message: e.message })
          console.error(e)
        }
      }
    }
    return {
      ...toRefs(props),
      getData
    }
  }
}
</script>
