export const statusMap = {
  0: { name: '试点' },
  1: { name: '上架' },
  2: { name: '下架' }
}
export const coursewareTypeMap = {
  ppt: { name: 'webPPT' },
  plan: { name: '教案' },
  speak: { name: '说课' },
  image: { name: '图片' },
  audio: { name: '音频' },
  video: { name: '视频' },
  swf: { name: 'flash' },
  qgroup: { name: '题目组' }
}
export const shareModeMap = {
  0: { name: '无限制' },
  1: { name: '禁止获取' },
  2: { name: '允许获取,禁止修改' },
  3: { name: '允许获取修改,禁止分享' }
}

export const shareModeEnum = {
  UNLIMIT: 0, // 无限制
  NOCOPY: 1, // 禁止获取
  NOEDIT: 2, // 可以获取但不可以编辑
  NOSHARE: 3// 可以获取编辑，不可以分享
}
