<template>
  <div style="position: relative">
    <common-loading v-if="loading" />
    <a-spin
      size="large"
      :spinning="fullscreenLoading"
      :tip="fullscreenLoadingText"
    >
      <a-card
        v-permission="PermissionMap.TEACHING_MATERIAL_EDIT"
        style="margin-bottom: 12px"
      >
        <span style="margin-right: 12px"
          >当前教材ID: {{ currentTeachingMaterial?.id || "--" }}</span
        >
        <span style="margin-right: 12px"
          >更新时间:
          {{
            store.state.currentPeriods?.section_resource?.updated_at || "--"
          }}</span
        >
        <span style="margin-right: 12px"
          >操作人员:
          {{
            store.state.currentPeriods?.section_resource
              ?.last_update_user_nickname || "--"
          }}</span
        >

        <upload-button
          :disabled="list.length"
          :title="'批量创建章节'"
          @getResult="handleBatchSaveSections"
        />
      </a-card>
      <a-layout class="body_layout">
        <a-layout-sider width="190" class="treeSelect">
          <a-spin :spinning="basicInfoLoading">
            <a-space>
              <a @click="handleGoBack">
                <a-tooltip title="返回上一页">
                  <back theme="filled" size="20" fill="#333" :strokeWidth="3" />
                </a-tooltip>
              </a>
              <a-breadcrumb style="font-size: 13px">
                <a-breadcrumb-item>{{
                  isOfficial ? "官方课件" : "我的课件"
                }}</a-breadcrumb-item>
                <a-breadcrumb-item>编辑课件</a-breadcrumb-item>
              </a-breadcrumb>
            </a-space>
            <!-- <a-button @click="handleTest">测试</a-button> -->
            <h2>
              <strong v-if="currentTitle?.length < 8">
                {{ currentTitle }}
              </strong>
              <strong v-else>
                <a-tooltip :title="currentTitle"
                  >{{ currentTitle?.slice(0, 7) }}...</a-tooltip
                >
              </strong>
            </h2>
            <h4
              v-if="
                !store.state.currentPeriods ||
                  store.state.currentPeriods?.name?.length < 6
              "
            >
              <strong
                >当前课时:{{ store.state.currentPeriods?.name || "无" }}</strong
              >
            </h4>
            <h4 v-else>
              <a-tooltip :title="store.state.currentPeriods?.name">
                <strong
                  >当前课时:{{
                    store.state.currentPeriods?.name?.slice(0, 5)
                  }}...</strong
                >
              </a-tooltip>
            </h4>
            <div>
              <a-image :src="currentImageSrc" class="bookImage" />
            </div>
            <a-spin :spinning="requestLoading">
              <trees
                v-if="!isError"
                v-model:list="list"
                v-model:allList="list"
                v-model:selectKeys="selectKeys"
                v-model:currentSelect="currentSelectKey"
                class="treeBody"
                @update="handleUpdate"
                :canEdit="true"
                @openPpt="handleOpenPPT"
                @showMask="handleShowMask"
                @beforeSelect="handleBeforeSelect"
                @showLoading="handleShowLoading"
                @onMoveItem="handleOnItemMove"
                @onContact="handleShowContactModal"
                @onMoreCoursewares="handleMoreCoursewares"
              ></trees>
            </a-spin>

            <a-button
              class="reload_btn"
              v-if="isError"
              type="primary"
              @click="handleReload"
              >刷新</a-button
            >
          </a-spin>
        </a-layout-sider>
        <a-layout-content style="position: relative">
          <div class="maskItem" v-if="showPPT"></div>
          <div
            ref="pptistInResource"
            id="pptistInResource"
            class="pptistInResource"
          ></div>
          <sheet-song-modal
            v-if="sheetModalVisible"
            :visible="sheetModalVisible"
            @onCancel="handleSheetModalCancel"
            @onOk="handleAddSheetSongOk"
          ></sheet-song-modal>
          <enjoy-song-modal
            v-if="enjoyModalVisible"
            :visible="enjoyModalVisible"
            @onCancel="handleEnjoyModalCancel"
            @onOk="handleAddEnjoySongOk"
          >
          </enjoy-song-modal>
          <music-rhythm-modal
            v-if="musicRhythmModalVisible"
            :visible="musicRhythmModalVisible"
            @onCancel="handleMusicRhythmModalCancel"
            @onOk="handleAddMusicRhythmOk"
          ></music-rhythm-modal>
          <contact-courseware-modal
            v-if="contactModalVisible"
            :visible="contactModalVisible"
            :bookInfo="currentBookInfo"
            @onCancel="handleCancelContactModal"
          />
          <knowledge-point-modal
            :visible="knowledgePointModalVisible"
            v-if="knowledgePointModalVisible"
            @onOk="handleAddKnowledgePoint"
            @onCancel="handelKnowledgePointModalCancel"
          ></knowledge-point-modal>
          <questions-bank-modal
            v-if="questionsBankModalVisible"
            :visible="questionsBankModalVisible"
            @onCancel="handleQuestionsBankModalCancel"
            @onOk="handleQuestionsBankModalOk"
          ></questions-bank-modal>
          <relate-courseware-modal
            v-if="relateCoursewareModalVisible"
            :clearKeys="relatePlanOrSpeak.clearKeys"
            :visible="relateCoursewareModalVisible"
            :coursewareId="relatePlanOrSpeak.coursewareId"
            :relate-coursewares="relatePlanOrSpeak.relate_coursewares || []"
            :relateType="relatePlanOrSpeak.type"
            :coursewareType="relatePlanOrSpeak.coursewareSource"
            @onCancel="handleRelateCoursewareModalCancel"
            @onOk="handleRelateCoursewareModalOk"
          ></relate-courseware-modal>
        </a-layout-content>
      </a-layout>
    </a-spin>
  </div>
</template>

<script>
import { computed, onMounted, ref, createVNode, reactive } from 'vue'
import { useStore } from 'vuex'
import Trees from './components/Trees.vue'
import { useRoute, useRouter } from 'vue-router'
import * as service from './service'
import { eventCenter, event } from '@evideo/frontend-utils'
import {
  bindNodeResourceId,
  getAllChapterNode,
  getFirstPeriodsPPT,
  queryNode
} from './components/commonUtil'
import { message, Modal } from 'ant-design-vue'
import {
  SaveResouceTypeMap,
  SaveTypeMap,
  SongListType,
  TeachMType
} from './components/config'
import config from '@/config'
import { PermissionMap } from '@/utils/auth'
import SheetSongModal from './components/SheetSongModal.vue'
import EnjoySongModal from './components/EnjoySongModal.vue'
import MusicRhythmModal from './components/MusicRhythmModal.vue'

import RelateCoursewareModal from '@/views/courseware/components/SelectRelateCoursewareModal.vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import CommonLoading from '@/components/common/CommonLoading'
import { Back } from '@icon-park/vue-next'
import KnowledgePointModal from './components/KnowledgePointModal.vue'
import { EditPptModeRuntimeConfig } from '@/components/ppt-editor/config'
import ContactCoursewareModal from './components/ContactCoursewareModal.vue'
import UploadButton from '@/components/common/UploadButton.vue'
import {
  getCoursewareDevelopVersion,
  getCoursewareInfo
} from '@/services/courseware'
import QuestionsBankModal from './components/QuestionsBankModal.vue'

export default {
  components: {
    Trees,
    SheetSongModal,
    EnjoySongModal,
    MusicRhythmModal,
    KnowledgePointModal,
    RelateCoursewareModal,
    Back,
    CommonLoading,
    ContactCoursewareModal,
    UploadButton,
    QuestionsBankModal
  },
  props: {
    dev: {
      type: Boolean,
      default: () => { return false }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.path.indexOf('preparation')) {
      eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
        app: config.pptist.name
      })
    }
    setTimeout(() => {
      next()
    }, 0)
  },
  setup(props) {
    // 是否是第一次打开
    const isFirstOpen = ref(true)
    const store = useStore()
    const state = store.state
    // 展开的节点
    const selectKeys = []
    const router = useRoute()
    // 教材id
    const id = router.params.id
    // 教材类型
    const teachingMaterialType = router.params.type
    const route = useRouter()

    // 教材目录信息
    const list = ref([])
    // 教材目录信息计算属性
    const dataList = computed(() => list)
    // 是否加载错误
    const isError = ref(false)
    // 是否展示ppt编辑区域
    const showPPT = ref(true)
    // 基础信息加载状态
    const basicInfoLoading = ref(true)
    // 是否是官方教材
    const isOfficial = computed(
      () =>
        !teachingMaterialType || teachingMaterialType === TeachMType.Official
    )

    // 当前的教材
    const currentTeachingMaterial = ref({})
    // 请求加载状态
    const requestLoading = ref(false)
    // 是否全屏加载
    const fullscreenLoading = ref(false)
    // 全屏加载提示
    const fullscreenLoadingText = ref('')

    // ppt挂载的div
    const pptistInResource = ref()
    // 是否为开发模式
    const isDev = ref(props.dev)
    const loading = ref(true)
    // 当前选择的节点
    const currentSelectKey = ref({ id: -1 })
    // 定时器列表
    const timerList = []

    // 关联教材弹窗可见性
    const contactModalVisible = ref(false)

    const currentBookInfo = ref({})

    // 展示全屏等待加载loading
    const showFullScreenLoading = (value, tip = 'loading', timeout = 3000) => {
      fullscreenLoading.value = value
      fullscreenLoadingText.value = tip
      if (value) {
        const timer = setInterval(() => {
          fullscreenLoading.value = false
          fullscreenLoadingText.value = ''
        }, timeout)
        timerList.push(timer)
      } else {
        // 清除所有定时器
        timerList.forEach(timer => {
          clearInterval(timer)
        })
      }
    }

    // 请求过程中将列表进行拦截
    const handleShowLoading = (value, text = '') => {
      fullscreenLoading.value = value
      if (value) {
        fullscreenLoadingText.value = text
      }
    }
    // 获取当前教材信息
    const getPreparationInfo = async id => {
      let func = null
      if (isOfficial.value) {
        func = service.getPreparationInfo
      } else {
        func = service.getTeacherPreparationInfo
      }
      if (func !== null) {
        const data = await func(id)
        currentTeachingMaterial.value = data
      }
    }
    // 获取保存课件所需的关联课件信息
    const getRelateCoursewareInfo = async () => {
      const relate_coursewares = JSON.parse(
        sessionStorage.getItem('relate_coursewares')
      )
      const delete_data = JSON.parse(sessionStorage.getItem('delete_data'))
      const id = state.currentPeriods?.section_resource?.id
      if (relate_coursewares) {
        if (id) {
          // 编辑ppt的情况:将已关联课件与要关联课件的id整合到relate_courseware_ids数组中
          relateCoursewareData.value = await getCoursewareInfo(id)
          relateCoursewareData.value.relate_courseware_ids = relateCoursewareData.value.relate_coursewares.map(
            item => item.id
          )
          // 收集sessionStorage中关联课件的id
          const relate_courseware_ids = relate_coursewares.map(item => item.id)
          relateCoursewareData.value.relate_courseware_ids = relateCoursewareData.value.relate_courseware_ids.concat(
            relate_courseware_ids
          )
        } else {
          // 新建ppt的情况:将要关联课件的id整合到relate_courseware_ids数组中
          const relate_courseware_ids = relate_coursewares.map(item => item.id)
          relateCoursewareData.value.relate_courseware_ids = relate_courseware_ids
        }
      } else if (delete_data) {
        relateCoursewareData.value.relate_courseware_ids = delete_data
      }
    }

    // 当前的title
    const currentTitle = computed(() => {
      return currentTeachingMaterial.value?.name
    })
    // 当前的图片
    const currentImageSrc = computed(() => {
      return currentTeachingMaterial.value?.poster
    })

    // 谱例歌曲的弹窗部分
    let sheetModalCallBack = null // 暂存的回调的对象
    const sheetModalVisible = ref(false)
    const handleSheetModalCancel = () => {
      sheetModalVisible.value = false
    }
    // 用户选择完谱例歌曲
    const handleAddSheetSongOk = data => {
      if (sheetModalCallBack) {
        sheetModalCallBack.reply(data)
      }
    }

    // 欣赏歌曲弹窗部分
    let enjoyModalCallBack = null // 欣赏歌曲的选择后回调对象
    const enjoyModalVisible = ref(false)
    const handleEnjoyModalCancel = () => {
      enjoyModalVisible.value = false
    }
    // 欣赏歌曲选择结束的回调
    const handleAddEnjoySongOk = data => {
      if (enjoyModalCallBack) {
        enjoyModalCallBack.reply(data)
      }
    }

    let knowledgePointModalCallBack = null // 知识点选择的回调对象
    const knowledgePointModalVisible = ref(false)
    const handelKnowledgePointModalCancel = () => {
      knowledgePointModalVisible.value = false
    }

    const handleAddKnowledgePoint = data => {
      if (knowledgePointModalCallBack) {
        knowledgePointModalCallBack.reply(data)
      }
    }
    // 题库弹窗可见性
    const questionsBankModalVisible = ref(false)
    // 题库弹窗回调
    let questionsBankModalCallBack = null

    // 节奏律动
    let musicRhythmModalCallBack = null // 欣赏歌曲的选择后回调对象
    const musicRhythmModalVisible = ref(false)
    const handleMusicRhythmModalCancel = () => {
      musicRhythmModalVisible.value = false
    }
    // 欣赏歌曲选择结束的回调
    const handleAddMusicRhythmOk = data => {
      if (musicRhythmModalCallBack) {
        musicRhythmModalCallBack.reply(data)
      }
    }

    // 题库弹窗关闭
    const handleQuestionsBankModalCancel = () => {
      questionsBankModalVisible.value = false
    }
    // 题库弹窗回调
    const handleQuestionsBankModalOk = data => {
      if (questionsBankModalCallBack) {
        questionsBankModalCallBack.reply(data)
      }
    }
    // 关联教案/说课弹框可见性
    const relateCoursewareModalVisible = ref(false)
    // 编辑关联教案/说课弹框回调
    let relateCoursewareModalCallBack = null
    // 新建关联教案/说课弹框回调
    let getNewPptDetailsCallBack = null
    // 关联弹框的数据
    const relatePlanOrSpeak = reactive({
      type: 'lesson',
      coursewareId: 0,
      coursewareSource: 'official',
      status_code: -1
    })
    // 关联说课/教案的数据
    const relateCoursewareData = ref({})

    // 关联说课/教案弹窗关闭
    const handleRelateCoursewareModalCancel = () => {
      relateCoursewareModalVisible.value = false
    }

    // 关联说课/教案弹窗回调
    const handleRelateCoursewareModalOk = data => {
      const { coursewareId } = data
      if (coursewareId !== 0) {
        const { relate_coursewares } = data
        relatePlanOrSpeak.relate_coursewares = relate_coursewares
        if (relateCoursewareModalCallBack) {
          relateCoursewareModalCallBack.reply(data)
        }
      } else if (coursewareId === 0) {
        if (relateCoursewareModalCallBack && getNewPptDetailsCallBack) {
          const relate_coursewares = JSON.parse(
            sessionStorage.getItem('relate_coursewares')
          )
          if (relate_coursewares) {
            relatePlanOrSpeak.relate_coursewares = relate_coursewares
            relateCoursewareModalCallBack.reply(data)
            getNewPptDetailsCallBack.reply(data)
          } else {
            relateCoursewareModalCallBack.reply(data)
            getNewPptDetailsCallBack.reply(data)
            relatePlanOrSpeak.relate_coursewares = []
          }
          message.success('关联成功')
        }
      }
    }

    // 初始化请求数据
    const getInitInfo = async () => {
      try {
        basicInfoLoading.value = true
        let reqFunc = null
        // 官方教材
        if (isOfficial.value) {
          reqFunc = service.getTeachmaterTreeInfo
        } else {
          // 教师的教材
          reqFunc = service.getTeacherTreeInfo
        }
        const data = await reqFunc(id)
        if (data) {
          list.value = data
          getAllChapterNode(list.value, selectKeys)
          isError.value = false
        }
        await getPreparationInfo(id)
        store.commit('setCurrentPeriods', undefined)
      } catch (error) {
        console.error(error)
        list.value = []
        isError.value = true
      } finally {
        basicInfoLoading.value = false
      }
    }

    // 更新树状信息
    const handleUpdate = async (params, callback = undefined) => {
      try {
        handleShowLoading(true, '更新目录中')
        console.log(
          `[resource-manage]:save tree info into preparation ${id} ===> `,
          params
        )
        let func = null
        if (isOfficial.value) {
          func = service.setTeachmaterTreeInfo
        } else {
          func = service.setTeacherTeacmaterTreeInfo
        }
        const data = await func(id, params)
        await handleReload()
        if (callback) {
          callback()
        }
        handleShowLoading(false)
        return data
      } catch (error) {
        console.error(error)
        handleShowLoading(false)
        throw error
      }
    }

    // 创建一份新的ppt
    const handleCreatePpt = async (
      url,
      uuid,
      callbackEvent,
      isAuto = false,
      previewImages
    ) => {
      await getRelateCoursewareInfo()
      // 通过事件监听会从通信SDK里拿到ppt的资源地址，然后请求接口将资源地址创建课件
      // 最后操作树状节点将resouce_id替换
      const params = {
        url,
        uuid,
        type: 'ppt',
        name: state.currentPeriods.name,
        book_id: id,
        book_section_id: state.currentPeriods.id,
        author_type: 'mohuan',
        // size大小先不计算，后续会补上
        size: 0,
        share_mode: currentTeachingMaterial.value.share_mode,
        relate_courseware_ids: relateCoursewareData.value.relate_courseware_ids,
        thumbnail_images: previewImages
      }
      try {
        handleShowLoading(true, '更新目录中')
        // 创建ppt
        let createFunc = null
        if (isOfficial.value) {
          createFunc = service.createPPT
        } else {
          createFunc = service.createTeacherPPT
        }
        const { id } = await createFunc(params)
        // 获取当前的节点
        const currentPeriodsNode = store.state.currentPeriods
        // 构造新的树状信息
        const treeInfo = bindNodeResourceId(list.value, currentPeriodsNode, id)
        // 更新树状信息
        await handleUpdate({ sections: treeInfo }, () => {
          message.success(`${isAuto ? '自动' : ''}保存ppt成功`)
          callbackEvent.reply({ errorcode: 0, errormessage: 'success' })
          // 更新保存在vuex中的当前打开的课时的信息
          const newVersionNode = queryNode(list.value, currentPeriodsNode.id)
          store.commit('setCurrentPeriods', newVersionNode)
        })
        handleShowLoading(false)
      } catch (error) {
        console.log(error)
        message.error(`${isAuto ? '自动' : ''}保存ppt失败，请重试`)
        callbackEvent.reply({
          errorcode: 1,
          errormessage: 'error to create ppt'
        })
        handleShowLoading(false)
      }
    }

    // 更新ppt
    const handleSavePptChange = async (
      url,
      uuid,
      callbackEvent,
      isAuto = false,
      previewImages
    ) => {
      await getRelateCoursewareInfo()
      // 通过事件监听会从通信SDK里拿到ppt的资源地址，然后请求接口将资源地址创建课件
      // 最后操作树状节点将resouce_id替换
      const params = {
        url,
        uuid,
        type: 'ppt',
        thumbnail_images: previewImages,
        relate_courseware_ids: relateCoursewareData.value.relate_courseware_ids
      }
      console.log(params.relate_courseware_ids)
      try {
        handleShowLoading(true, '更新目录中')
        const id = state.currentPeriods?.section_resource?.id
        // 创建ppt
        let func = null
        if (isOfficial.value) {
          func = service.savePPT
        } else {
          func = service.saveTeacherPPT
        }
        if (isAuto) {
          // 自动保存前判断ppt是否已被修改，防止切换到其他课件，其他课件被覆盖
          eventCenter.send(
            event.PPTIST_IS_MODIFIED,
            null,
            async ({ isModified }) => {
              console.log('resource auto save ppt state:', isModified)
              if (isModified) {
                await func(id, params)
                await handleReload()
                // message.success(`${isAuto ? '自动' : ''}保存ppt成功`)
                callbackEvent.reply({ errorcode: 0, errormessage: 'success' })
                const currentPeriodsNode = store.state.currentPeriods
                const newVersionNode = queryNode(
                  list.value,
                  currentPeriodsNode.id
                )
                store.commit('setCurrentPeriods', newVersionNode)
              }
              handleShowLoading(false)
            }
          )
        } else {
          await func(id, params)
          await handleReload()
          // message.success(`${isAuto ? '自动' : ''}保存ppt成功`)
          callbackEvent.reply({ errorcode: 0, errormessage: 'success' })
          handleShowLoading(false)
          const currentPeriodsNode = store.state.currentPeriods
          const newVersionNode = queryNode(list.value, currentPeriodsNode.id)
          store.commit('setCurrentPeriods', newVersionNode)
        }
      } catch (error) {
        console.log(error)
        callbackEvent.reply({
          errorcode: 1,
          errormessage: 'error to save ppt'
        })
        // message.error(`${isAuto ? '自动' : ''}保存ppt失败，请重试`)
        handleShowLoading(false)
      }
    }

    // ppt保存事件监听
    const registerSaveListen = () => {
      // 监听新增ppt事件
      eventCenter.sub(event.RESOURCE_UPDATE_RESOURCE, (res, callbackEvent) => {
        console.log('保存ppt', res, store.state.currentPeriods)
        if (res.resource_type === SaveResouceTypeMap.PPT) {
          if (res.type === SaveTypeMap.CREATE) {
            handleCreatePpt(res.url, res.uuid, callbackEvent, res.isAuto, res.previewImages || [])
          } else {
            if (!store.state.currentPeriods?.section_resource?.url) {
              handleCreatePpt(res.url, res.uuid, callbackEvent, res.isAuto, res.previewImages || [])
            } else {
              handleSavePptChange(res.url, res.uuid, callbackEvent, res.isAuto, res.previewImages || [])
            }
          }
        }
      })
      eventCenter.sub(event.COMMON_SHOW_LOADING, res => {
        const { loading, timeout, loadingText } = res
        showFullScreenLoading(loading, loadingText, timeout || 3000)
      })
    }

    // 注册打开弹窗的事件
    const registerSongModalListen = () => {
      eventCenter.sub(event.RESOURCE_OPEN_SONG_MODAL, (res, callbackEvent) => {
        const { type } = res
        if (type === SongListType.sheet) {
          sheetModalVisible.value = true
          sheetModalCallBack = callbackEvent
        } else if (type === SongListType.enjoy) {
          enjoyModalVisible.value = true
          enjoyModalCallBack = callbackEvent
        } else if (type === SongListType.QUESTIONS) {
          questionsBankModalVisible.value = true
          questionsBankModalCallBack = callbackEvent
        } else if (type === SongListType.RHYTHM) {
          musicRhythmModalVisible.value = true
          musicRhythmModalCallBack = callbackEvent
        }
      })
    }

    // 注册打开关联弹窗事件
    const registerRelateCoursewareModalListen = () => {
      // 关联说课/教案弹窗
      eventCenter.sub('openLessonOrSpeakModal', (res, callbackEvent) => {
        const { type, coursewareId } = res
        relatePlanOrSpeak.type = type
        relatePlanOrSpeak.coursewareId = coursewareId
        relateCoursewareModalVisible.value = true
        relateCoursewareModalCallBack = callbackEvent
      })

      eventCenter.sub('getNewPptDetails', (res, callbackEvent) => {
        getNewPptDetailsCallBack = callbackEvent
        const relate_coursewares = JSON.parse(
          sessionStorage.getItem('relate_coursewares')
        )
        if (relate_coursewares) {
          getNewPptDetailsCallBack.reply({
            relate_coursewares: relate_coursewares
          })
        }
      })

      // todo 取消关联事件
      eventCenter.sub(
        'cancelLessonOrSpeakRelate',
        async (res, callbackEvent) => {
          let relate_lessons = JSON.parse(
            sessionStorage.getItem('relate_lessons')
          )
          let relate_speaks = JSON.parse(
            sessionStorage.getItem('relate_speaks')
          )
          let relate_coursewares = JSON.parse(
            sessionStorage.getItem('relate_coursewares')
          )
          console.log('[关联课件]取消关联事件', res, callbackEvent)
          const { coursewareId, relate_courseware_ids, type } = res
          if (
            relate_coursewares &&
            Object.keys(relate_coursewares).length !== 0
          ) {
            relatePlanOrSpeak.relate_coursewares = relate_coursewares.filter(
              item => {
                return relate_courseware_ids.indexOf(item.id) !== -1
              }
            )
          }
          sessionStorage.setItem(
            'delete_data',
            JSON.stringify(relate_courseware_ids)
          )
          // 一、在编辑ppt时取消关联的情况：直接调用接口
          if (coursewareId !== 0) {
            await service.savePPT(coursewareId, {
              relate_courseware_ids: relate_courseware_ids
            })
            relatePlanOrSpeak.status_code = 0
            callbackEvent.reply({
              status_code: relatePlanOrSpeak.status_code,
              coursewareSourse: 'official',
              coursewareId: coursewareId
            })
            message.success('取消关联成功')
          }
          // 二、将取消关联后的数据进行存储
          if (type === 'lesson' && relate_lessons) {
            relate_lessons = relate_lessons.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_lessons',
              JSON.stringify(relate_lessons)
            )
          } else if (type === 'speak' && relate_speaks) {
            relate_speaks = relate_speaks.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_speaks',
              JSON.stringify(relate_speaks)
            )
          }
          if (relate_coursewares) {
            relate_coursewares = relate_coursewares.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_coursewares',
              JSON.stringify(relate_coursewares)
            )
          }
          // 三、将处理过后的数据回调给ppt
          if (coursewareId === 0) {
            callbackEvent.reply({
              status_code: relatePlanOrSpeak.status_code,
              coursewareSourse: 'official',
              coursewareId: coursewareId,
              relate_coursewares: relate_coursewares
            })
            message.success('取消关联成功')
          }
        }
      )
    }

    const registerKnowledgePointModalListen = () => {
      eventCenter.sub(
        event.RESOURCE_OPEN_KNOWLEDGE_POINTS_MODAL,
        (res, callbackEvent) => {
          knowledgePointModalVisible.value = true
          knowledgePointModalCallBack = callbackEvent
        }
      )
    }

    // 切换前判断ppt是否保存了监听
    const handleBeforeSelect = next => {
      if (isFirstOpen.value) {
        isFirstOpen.value = false
        next()
        return
      }
      eventCenter.send(event.PPTIST_IS_MODIFIED, null, ({ isModified }) => {
        if (isModified) {
          Modal.confirm({
            title: '有尚未保存的ppt内容，确定要离开吗?',
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              next()
              sessionStorage.clear()
              relatePlanOrSpeak.clearKeys = true
            }
          })
        } else {
          next()
        }
      })
    }
    // 全屏事件监听
    const registerFullScreenListen = () => {
      const isFullscreen = () =>
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen

      // 全屏事件的处理
      eventCenter.sub(event.COMMON_FULL_SCREEN, res => {
        const element = pptistInResource.value
        if (res.fullScreen === true) {
          if (!isFullscreen()) {
            // 进入全屏
            if (element.requestFullScreen) {
              element.requestFullScreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen()
            }
          }
        } else {
          if (document.exitFullScreen) {
            document.exitFullScreen()
          } else if (document.msExitFullScreen) {
            document.msExitFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          }
        }
      })
    }

    // 打开ppt事件
    const handleOpenPPT = async params => {
      sessionStorage.clear()
      relatePlanOrSpeak.relate_coursewares = []
      showPPT.value = false
      window.scrollTo(0, 0)
      console.log('handleOpenPPT-params', params, params.section_resource)
      // 编辑ppt之前调用该接口，生成开发版
      let url
      if (params.section_resource?.url) {
        const data = await getCoursewareDevelopVersion(
          params.section_resource.id
        )
        url = data.url
      }
      eventCenter.send(event.PPTIST_LOAD_PPT, {
        url: url || undefined,
        runtime: {
          state: {
            screening: false,
            screenType: 'edit-check'
          },
          extData: {
            coursewareId: params.section_resource?.id ?? 0,
            coursewareSource: 'official',
            pptName: params?.name
          }
        }
      })
      isFirstOpen.value = false
    }

    // 加载ppt事件
    const loadPptistApp = () => {
      const pptEntryUrl = isDev.value
        ? localStorage.getItem('pptist_url') || config.pptist.entry
        : config.pptist.entry
      const runtime = EditPptModeRuntimeConfig
      eventCenter.send(
        event.COMMON_LOAD_MICRO_APP,
        {
          app: config.pptist.name,
          el: pptistInResource.value,
          entry: pptEntryUrl,
          args: {
            token: store.state.token,
            microAppUrl: pptEntryUrl,
            appName: config.pptist.name,
            runtime
          },
          screening: false,
          screenType: 'edit-check',
          exitScreenType: 'edit-check'
        },
        res => {
          if (res) {
          }
        }
      )
      eventCenter.sub(`${config.pptist.name}-load-ok`, () => {
        // 选出第一份ppt
        const firstPPT = getFirstPeriodsPPT(list.value, [])
        if (firstPPT) {
          store.commit('setCurrentPeriods', firstPPT)
          handleOpenPPT(firstPPT)
          currentSelectKey.value.id = firstPPT.id
        }
        loading.value = false
      })
      // setTimeout(() => {
      //   // 选出第一份ppt
      //   const firstPPT = getFirstPeriodsPPT(list.value, [])
      //   if (firstPPT) {
      //     store.commit('setCurrentPeriods', firstPPT)
      //     handleOpenPPT(firstPPT)
      //     currentSelectKey.value.id = firstPPT.id
      //   }
      //   loading.value = false
      // }, 500)
    }

    const changeCollpased = collpased => {
      eventCenter.send(event.COMMON_CHANGE_COLLPASED, { collpased })
    }

    // 设置保存事件监听
    onMounted(async () => {
      window.scrollTo(0, 0)
      await getInitInfo()
      changeCollpased(true)
      loadPptistApp()
      registerFullScreenListen()
      registerSaveListen()
      registerSongModalListen()
      registerRelateCoursewareModalListen()
      registerKnowledgePointModalListen()
    })

    // 重新加载树状信息
    const handleReload = async () => {
      try {
        let func = null
        if (isOfficial.value) {
          func = service.getTeachmaterTreeInfo
        } else {
          func = service.getTeacherTreeInfo
        }
        const data = await func(id)
        if (data) {
          list.value = data
          isError.value = false
        }
      } catch (error) {
        list.value = []
        isError.value = true
      }
    }

    // 返回
    const handleGoBack = () => {
      handleBeforeSelect(() => {
        route.push('/teaching-material')
      })
    }

    // 隐藏PPT编辑部分
    const handleShowMask = () => {
      showPPT.value = true
    }

    const handleTest = () => {
      eventCenter.send(event.RESOURCE_OPEN_KNOWLEDGE_POINTS_MODAL, data => {
        console.log(data)
      })
    }

    // 节点移动了后数据变化
    const handleOnItemMove = list => {
      handleUpdate({ sections: list })
    }

    const handleShowContactModal = data => {
      console.log(data)
      currentBookInfo.value = data
      contactModalVisible.value = true
    }

    const handleMoreCoursewares = data => {
      route.push(
        `/resource/courseware/management?book_section_id=${data.book_section_id}&book_name=${currentTitle.value}-${data.book_name}`
      )
    }

    const handleCancelContactModal = () => {
      currentBookInfo.value = {}
      contactModalVisible.value = false
    }

    const handleBeforeUpload = file => {
      try {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = async result => {
          const targetNum = result.target.result
          console.log(1, targetNum)

          // await service.setTeachmaterTreeInfo(id, params)
          // await handleReload()
        }
        return false
      } catch (e) {
        console.error(e)
        message.error('请求错误或超时')
      }
    }

    const handleBatchSaveSections = async res => {
      try {
        await service.setTeachmaterTreeInfo(id, res)
        await handleReload()
        message.success('导入成功')
      } catch (e) {
        console.error(e)
        message.error('请求错误或超时')
      }
    }

    return {
      currentTeachingMaterial,
      list,
      store,
      currentTitle,
      currentImageSrc,
      dataList,
      selectKeys,
      currentSelectKey,
      isError,
      showPPT,
      loading,
      pptistInResource,
      PermissionMap,
      relatePlanOrSpeak,
      relateCoursewareData,
      sheetModalVisible,
      enjoyModalVisible,
      musicRhythmModalVisible,
      questionsBankModalVisible,
      relateCoursewareModalVisible,
      basicInfoLoading,
      teachingMaterialType,
      isOfficial,
      requestLoading,
      fullscreenLoading,
      fullscreenLoadingText,
      knowledgePointModalVisible,
      contactModalVisible,
      currentBookInfo,
      handleGoBack,
      handleReload,
      handleUpdate,
      handleShowMask,
      handleSheetModalCancel,
      handleQuestionsBankModalCancel,
      handleTest,
      handleAddSheetSongOk,
      handleEnjoyModalCancel,
      handleAddEnjoySongOk,
      handleMusicRhythmModalCancel,
      handleAddMusicRhythmOk,
      handleQuestionsBankModalOk,
      handleRelateCoursewareModalCancel,
      handleRelateCoursewareModalOk,
      handleBeforeSelect,
      handleOpenPPT,
      handleShowLoading,
      handleOnItemMove,
      handleShowContactModal,
      handleCancelContactModal,
      handleBeforeUpload,
      handleBatchSaveSections,
      handelKnowledgePointModalCancel,
      handleAddKnowledgePoint,
      handleMoreCoursewares
    }
  }
}
</script>

<style lang="less">
.treeSelect {
  padding-left: 20px;
  padding-top: 20px;
  background: #fff;
  height: 100%;
  border-right: 1px solid rgb(241 242 246);
  box-shadow: 1px 0 4px rgb(0 21 41 / 18%);
  z-index: 2;
}

/*定义滚动条轨道 内阴影+圆角*/
.treeSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.treeSelect ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}

/*定义滑块 内阴影+圆角*/
.treeSelect ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: #c1c1c1;
}

.treeBody {
  overflow-y: auto;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 20px;
  height: 40vh;
}

.bookImage {
  width: 150px;
  height: 200px;
}
.reload_btn {
  margin-top: 20px;
}
.pptistInResource {
  width: 100%;
  height: 100%;
}

.maskItem {
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(241 242 246);
}

.body_layout {
  height: calc(100vh - 100px);
}
</style>
