<template>
  <div class="noselect">
    <span
      :style="
        isSelect && { color: store.state.themeColor, fontWeight: 'bloder' }
      "
      @click="handleSelect"
      class="name_title"
      :title="valueData.name"
    >
      <strong class="periods_icon">·</strong>
      {{ valueData.name }}
    </span>
    <span class="opt_span">
      <a-dropdown :trigger="['click']" v-model:visible="optVisible">
        <EllipsisOutlined
          :style="
            optVisible && {
              color: store.state.themeColor,
              fontWeight: 'bloder',
            }
          "
        />
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleEdit">
              <EditOutlined />
              <span class="options-btn">编辑</span>
            </a-menu-item>
            <a-popconfirm
              title="是否确认删除该课时？"
              ok-text="确定"
              cancelText="取消"
              @confirm="handleDelete"
            >
              <a-menu-item><DeleteOutlined />删除</a-menu-item>
            </a-popconfirm>
            <a-menu-item v-if="canMoveUp" @click="() => handleMoveItem('up')"><ArrowUpOutlined />上移</a-menu-item>
            <a-menu-item v-if="canMoveDown" @click="() => handleMoveItem('down')"><ArrowDownOutlined />下移</a-menu-item>
            <a-menu-item @click="handleContactCourseware"><ColumnWidthOutlined />关联资源</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </span>
  </div>
</template>

<script>
import { computed, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import { deleteChild, moveChildItem } from './commonUtil'
import { EditOutlined, DeleteOutlined, EllipsisOutlined, ArrowUpOutlined, ArrowDownOutlined, ColumnWidthOutlined } from '@ant-design/icons-vue'
import { TreeItemType } from './config'
export default {
  name: 'Periods',
  components: {
    EditOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    ColumnWidthOutlined
  },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    },
    selectKeys: {
      type: Array,
      default: () => { return [] }
    },
    currentSelect: {
      type: Object,
      default: () => { return { id: -1 } }
    },
    allList: {
      type: Array,
      default: () => { return [] }
    },
    canEdit: {
      type: Boolean,
      default: () => { return false }
    },
    moveUp: {
      type: Boolean,
      default: () => { return true }
    },
    moveDown: {
      type: Boolean,
      dafault: () => { return true }
    }
  },
  setup (props, { emit }) {
    // 当前节点的值
    const valueData = computed(() => props.value)
    // 打开的节点集合
    const selectKeysValue = reactive(props.selectKeys)
    // 当前选中的节点id
    const currentSelect = computed(() => props.currentSelect)
    const store = useStore()
    // 所有的数据
    const list = computed(() => props.allList)
    // 是否选中了当前节点
    const isSelect = computed(() => {
      return currentSelect.value.id === valueData.value.id
    })
    // 是否可以编辑
    const canEditValue = ref(props.canEdit)

    // 操作选项是否展开
    const optVisible = ref(false)

    const canMoveUp = computed(() => props.moveUp)
    const canMoveDown = computed(() => props.moveDown)

    // 点击当前节点
    const handleSelect = () => {
      // 这个节点是当前选中高亮的节点
      if (currentSelect.value.id === valueData.value.id) {
        // 取消选中高亮
        emit('updateCurrentSelect', -1)
      } else {
        // 如果不是选中高亮的节点
        // 当前有打开的ppt且不是我们选择的ppt
        if (store.state.currentPeriods !== undefined) {
          if (store.state.currentPeriods && store.state.currentPeriods?.id !== valueData.value.id) {
            // 查询当前打开的ppt是否已经保存
            emit('beforeSelect', () => {
              // 将当前节点高亮
              emit('updateCurrentSelect', valueData.value.id)
              // 当前打开的ppt不是我们现在选择的ppt

              // 将当前打开的ppt改为选中的这份，并通知PPT打开
              store.commit('setCurrentPeriods', valueData.value)
              emit('openPpt', valueData.value)
            })
          } else {
            // 当前节点是打开ppt独赢的节点
            // 将当前节点高亮
            emit('updateCurrentSelect', valueData.value.id)
          }
        } else {
          // 当前没有打开的ppt
          emit('updateCurrentSelect', valueData.value.id)
          // 将当前打开的ppt改为选中的这份，并通知PPT打开
          store.commit('setCurrentPeriods', valueData.value)
          emit('openPpt', valueData.value)
        }
      }
    }

    // 课时节点删除
    const handleDelete = () => {
      optVisible.value = false
      // 如果删除的ppt正在打开状态
      if (store.state.currentPeriods?.id === valueData.value.id) {
        store.commit('setCurrentPeriods', undefined)
        emit('showMask')
      }
      const params = deleteChild(list.value, valueData.value)
      emit('onDelete', params)
    }

    // 编辑操作
    const handleEdit = () => {
      optVisible.value = false
      emit('onEdit', valueData.value)
    }

    const handleMoveItem = (type = 'up') => {
      const trees = [{ id: -1, name: '根节点', type: TreeItemType.Chapter, children: [...toRaw(list.value)] }]
      const newTrees = moveChildItem(trees, valueData.value, type)
      const params = newTrees.childList[0]
      optVisible.value = false
      emit('moveChildItem', params?.children)
    }

    const handleContactCourseware = () => {
      console.log('关联课件', valueData)
      optVisible.value = false
      emit('onContractCourseware', { book_id: valueData.value.book_id, book_section_id: valueData.value.id })
    }

    return {
      valueData,
      isSelect,
      store,
      selectKeysValue,
      canEditValue,
      optVisible,
      canMoveUp,
      canMoveDown,
      handleSelect,
      handleEdit,
      handleDelete,
      handleMoveItem,
      handleContactCourseware
    }
  }

}
</script>

<style lang="less">
.noselect {
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.periods_icon {
  margin-right: 5px;
}

.options {
  margin-left: 10px;
}

.options-btn {
  margin-top: 5px;
  margin-bottom: 5px;
}

.name_title {
  padding: 0px 5px;
  width: 70%;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  display: inline-block;
  cursor: default;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
}

.opt_span {
  vertical-align: middle;
}
</style>
