<template>
  <a-modal
    :width="1000"
    :maskClosable="false"
    title="选择题目"
    :visible="visibleValue"
    @cancel="handleCancel"
    class="modal_style"
    :footer="null"
    :destroyOnClose="true"
    style="top: 10px"
  >
    <a-form layout="inline" @keydown.enter="handleSearchBtnClick">
      <a-row style="width: 100%" :gutter="[16, 16]">
        <a-col :md="8">
          <a-form-item label="题型">
            <a-select
              :allowClear="true"
              mode="multiple"
              v-model:value="formData.types"
              :options="QuestionTypeOptions"
              placeholder="请选择题型"
            ></a-select>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="摘要">
            <a-input
              v-model:value="formData.summary"
              placeholder="请输入摘要"
            ></a-input>
          </a-form-item>
        </a-col>
        <!-- <a-col :md="8" :sm="24">
          <a-form-item label="题干">
            <a-input
              v-model:value="formData.title"
              placeholder="请输入题干"
            ></a-input>
          </a-form-item>
        </a-col> -->
        <a-col :md="8" :sm="24">
          <a-form-item label="标签">
            <a-select
          v-model:value="formData.tags"
          mode="multiple"
          placeholder="请选择标签"
          :options="tagOpts"
          @search="handleTagSearch"
          @popupScroll="handlePopupScroll"
          @change="handleSearchBtnClick"
          @blur="()=>handleTagSearch('')"
        />
        </a-form-item>
        </a-col>
        <a-col :md="10">
          <a-form-item label="难度">
            <CommonRadio
          v-model:value="formData.level"
          :options="DifficultyType"
          @handleClick="handleSearchBtnClick"
        />
          </a-form-item>
        </a-col>
        <a-col :md="4">
          <a-space>
            <a-button @click="handleResetForm"> 重置 </a-button>
            <a-button type="primary" @click="handleSearchBtnClick">
              查询
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form>
    <a-tabs v-model:activeKey="currentTabKey" @change="handleResetForm">
      <a-tab-pane v-if="tabSet.has('official')" key="official" tab="平台题库">
        <!-- 官方曲库列表 -->
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
          :locale="locale"
        >
          <template #questionstype="{ text }">
            <span>{{ questionType[text] }}</span>
          </template>
          <template #difficulty="{ text }">
            <span>
              <a-tag
                :key="text"
                :color="text === 0 ? 'green' : text === 1 ? 'blue' : 'red'"
              >
              {{ difficultyType[text] }}
              </a-tag>
            </span>
          </template>
          <template #tags="{ text }">
            <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
          </template>
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">添加</a>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane v-if="tabSet.has('teacher')" key="teacher" tab="校端题库">
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
        >
        <template #questionstype="{ text }">
            <span>{{ questionType[text] }}</span>
          </template>
          <template #difficulty="{ text }">
            <span>
              <a-tag
                :key="level"
                :color="text === 0 ? 'green' : text === 1 ? 'blue' : 'red'"
              >
              {{ difficultyType[text] }}
              </a-tag>
            </span>
          </template>
          <template #tags="{ text }">
            <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
          </template>
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">添加</a>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import {
  computed,
  onBeforeMount,
  ref,
  createVNode,
  watch,
  reactive
} from 'vue'
import { getInfoPermissionFromUser } from './commonUtil'
import * as service from '../service'
import {
  difficultyType,
  DifficultyType,
  questionType,
  QuestionTypeOptions
} from './config'
import { debounce } from '@/utils/common'
import Empty from '@/components/common/Empty.vue'
import CommonRadio from '@/components/common/CommonRadio'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  components: {
    CommonRadio
  },
  setup (props, { emit }) {
    // 弹窗可见性
    const visibleValue = computed(() => props.visible)
    // 关闭事件
    const handleCancel = () => {
      emit('onCancel')
    }

    // tab列的key值
    const tabKey = getInfoPermissionFromUser()
    // computed属性的tabkey值的集合
    const tabSet = computed(() => new Set(tabKey))

    const currentTabKey = ref(tabKey[0] || null)

    const formData = ref({
      title: undefined,
      summary: undefined,
      level: -1,
      type: undefined,
      tag: undefined,
      types: undefined
    })

    const tagParams = reactive({
      pageSize: 50,
      page: 1,
      keyword: ''
    })
    const tagOpts = ref([])

    // const getTagSearch = debounce(async (value) => {
    //   let SearchFunc = null
    //   if (currentTabKey.value === 'official') {
    //     SearchFunc = service.getOfficialTag
    //   } else if (currentTabKey.value === 'personal') {
    //     SearchFunc = service.getTeacherTags
    //   }
    //   if (SearchFunc !== null) {
    //     const tagList = await SearchFunc({ keyword: value, current: 1, per_page: 20 })
    //     tagOpts.value = tagList.items.map(item => { return { label: item.tag, value: item.tag } })
    //   }
    // })

    // 列表数据
    const dataList = ref([])
    const cloumns = [
      {
        title: '题目编号',
        dataIndex: 'id',
        key: 'id',
        ellipsis: true,
        width: 40
      },
      {
        title: '题型',
        dataIndex: 'type',
        slots: { customRender: 'questionstype' },
        key: 'type',
        ellipsis: true,
        width: 40
      },
      {
        title: '摘要',
        dataIndex: 'summary',
        key: 'summary',
        width: 80,
        ellipsis: true
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        width: 80,
        ellipsis: true
      },
      // {
      //   title: '题目内容',
      //   dataIndex: '',
      //   key: 'title',
      //   width: 60,
      //   ellipsis: true
      // },
      {
        title: '难度',
        dataIndex: 'level',
        slots: { customRender: 'difficulty' },
        key: 'level',
        width: 80,
        ellipsis: true,
        align: 'center'
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]
    let currentPage = 1
    const handlePageChange = page => {
      currentPage = page
      handleSearch()
    }
    // 第一次进入页面
    const isFirst = ref(false)

    // 检索为空提示
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, newValue => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, {
          value: isFirst.value,
          title: '请扫码联系魔欢平台反馈所需题目'
        })
      }
    })

    const paginations = ref({
      current: currentPage,
      pageSize: 10,
      onChange: handlePageChange
    })
    const loading = ref(false)

    const queryOfficialList = async params => {
      try {
        loading.value = true
        if (params.types === undefined || params.types.length === 0) {
          // 去除不需要的题型
          params.types = [1, 2, 3, 4, 8, 9, 10, 11, 12, 14]
        }
        const data = await service.getOfficialQuestionsBankSong({
          ...params,
          per_page: paginations.value.pageSize,
          page: currentPage,
          except_statuses: [2]
        })
        paginations.value = {
          ...paginations.value,
          ...data.meta,
          pageSize: +data.meta.per_page,
          current: data.meta.current_page
        }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const queryTeacherList = async params => {
      try {
        loading.value = true
        if (params.types === undefined || params.types.length === 0) {
          // 去除不需要的题型
          params.types = [1, 2, 3, 4, 8, 9, 10, 11, 12, 14]
        }
        const data = await service.getTeacherQuestionsBankSong({
          ...params,
          per_page: paginations.value.pageSize,
          page: currentPage,
          except_statuses: [2]
        })
        paginations.value = {
          ...paginations.value,
          ...data.meta,
          pageSize: +data.meta.per_page,
          current: data.meta.current_page
        }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const handleSearch = async () => {
      let searchFunc = null
      if (currentTabKey.value === 'official') {
        searchFunc = queryOfficialList
      } else if (currentTabKey.value === 'teacher') {
        searchFunc = queryTeacherList
      }

      if (searchFunc !== null) {
        const params = JSON.parse(JSON.stringify({ ...formData.value }))
        if (params.level === -1) {
          params.level = undefined
        }
        await searchFunc(params)
      }
    }

    const handleSearchBtnClick = async () => {
      currentPage = 1
      await handleSearch()
      handleTagSearch('')
    }

    const handleResetForm = () => {
      formData.value = {
        title: undefined,
        summary: undefined,
        level: -1,
        type: undefined,
        tag: undefined,
        types: undefined
      }
      // getTagSearch('')
      currentPage = 1
      getTagsOptions()
      handleSearch()
    }
    const getTagsOptions = debounce(async () => {
      const params = {
        per_page: tagParams.pageSize,
        current: tagParams.page,
        keyword: tagParams.keyword
      }
      let getTagsService = null
      if (currentTabKey.value === 'official') {
        getTagsService = service.getOfficialQuestionTag
      } else if (currentTabKey.value === 'teacher') {
        getTagsService = service.getTeacherQuestionTag
      }

      if (getTagsService !== null) {
        tagOpts.value = (await getTagsService(params)).items.map(i => {
          i.label = i.tag
          i.value = i.tag
          return i
        })
      }
    })

    const handleTagSearch = v => {
      tagParams.page = 1
      tagParams.keyword = v
      getTagsOptions()
    }
    const handlePopupScroll = e => {
      if (e.target.scrollTop === e.target.clientHeight) {
      }
      console.log(e)
      console.log(e.target.clientHeight)
    }

    onBeforeMount(async () => {
      await getTagsOptions()
      handleSearch().then(() => {
        isFirst.value = true
      })
    })

    const handleAddSong = record => {
      emit('onOk', { sid: record.id, source: currentTabKey.value })
      emit('onCancel')
    }

    return {
      visibleValue,
      currentTabKey,
      tabSet,
      dataList,
      cloumns,
      paginations,
      loading,
      difficultyType,
      DifficultyType,
      questionType,
      QuestionTypeOptions,
      formData,
      tagOpts,
      // getTagSearch,
      handleSearchBtnClick,
      handleSearch,
      handleResetForm,
      handleCancel,
      queryOfficialList,
      handleAddSong,
      locale,
      handleTagSearch,
      handlePopupScroll
    }
  }
}
</script>

<style>
.modal_style {
  min-height: 500px;
}
</style>
