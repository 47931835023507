import { request } from '@/utils/http'
import { questionsBankRequest } from '@/utils/http/questionsBank-service'
import config from '@/config'
import store from '@/store'

// 获取教材树状信息
export const getTeachmaterTreeInfo = (id: any) =>
  request('GET', `/official/book/${id}/sections`)

// 设置教材树状信息
export const setTeachmaterTreeInfo = (id: any, params: any) =>
  request('POST', `/official/book/${id}/set-sections`, params)

// 创建ppt
export const createPPT = (params: { url: string }) =>
  request('POST', '/official/courseware/create', params)

// 保存ppt
export const savePPT = (id: any, params: { url: string }) =>
  request('POST', `/official/courseware/${id}/edit`, params)

// 获取官方谱例歌曲
export const getOfficialSheetSong = (params: any) =>
  request('GET', '/official/sheet-songs', params)

export const getOfficialEnjoySong = (params: any) =>
  request('GET', '/official/enjoy-songs', params)

// 获取教材信息
export const getPreparationInfo = (id: any) =>
  request('GET', `/official/book/${id}`)

// 获取教师教材信息
export const getTeacherPreparationInfo = (id: any) =>
  request('GET', `/teacher/book/${id}`)

// 获取教师教材目录结构
export const getTeacherTreeInfo = (id: any) =>
  request('GET', `/teacher/book/${id}/sections`)

// 设置教师教材目录结构
export const setTeacherTeacmaterTreeInfo = (id: any, params: any) =>
  request('POST', `/teacher/book/${id}/set-sections`, params)

// 创建教师ppt
export const createTeacherPPT = (params: { url: string }) =>
  request('POST', '/teacher/courseware/create', params)

// 保存教师PPT
export const saveTeacherPPT = (id: any, params: any) =>
  request('POST', `/teacher/courseware/${id}/edit`, params)

// 获取教师谱例歌曲
export const getTeacherSheetSong = (params: any) =>
  request('GET', '/teacher/sheet-songs', params)

// 获取教师欣赏歌曲
export const getTeacherEnjoySong = (params: any) =>
  request('GET', '/teacher/enjoy-songs', params)

// 获取官方课件（排除掉了分享出来的课件）
export const getOfficialCourseware = (params: any) =>
  request('GET', '/official/coursewares', { ...params, show_swf: 1 })

// 编辑官方谱例歌曲
export const editOfficialSheetSong = (id: string, params: any) =>
  request('POST', `/official/sheet-song/${id}/edit`, params)

// 编辑官方欣赏歌曲
export const editOfficialEnjoySong = (id: string, params: any) =>
  request('POST', `/official/enjoy-song/${id}/edit`, params)

// 编辑官方课件
export const editOfficialCourseware = (id: string, params: any) =>
  request('POST', `/official/courseware/${id}/edit`, params)
// 获取官方知识点
export const queryOfficialKnowledgePoint = (params: any) =>
  request('GET', '/official/knowledge-points', params)

// 获取教师知识点
export const queryTeacherKnowledgePoint = (params: any) =>
  request('GET', '/teacher/knowledge-points', params)

export const getOfficialKnowledgePoint = (id: any) =>
  request('GET', `/official/knowledge-point/${id}`)

export const getTeacherKnowledgePoint = (id: any) =>
  request('GET', `/teacher/knowledge-point/${id}`)

// 获取官方律动歌曲1
export const getRhythmEnjoySong = (params: any) =>
  request('GET', '/official/music-rhythms', params)
// 获取官方题库
export const getOfficialQuestionsBankSong = (params: any) =>
  questionsBankRequest('GET', '/official/demo-questions', params)

export const getTeacherQuestionsBankSong = (params: any) =>
  questionsBankRequest('GET', '/school/demo-questions', params)

export const getOfficialQuestionTag = (params: any) =>
  questionsBankRequest('GET', '/official/tags', params)

export const getTeacherQuestionTag = (params: any) =>
  questionsBankRequest('GET', '/school/tags', params)

// 获取律动歌曲
export const getMusicRhythm = async (params: any) => {
  return (await fetch(
    `${store.state.baseURL}/teacher/userMelodyFiles?name=${params.name}&type=${params.type}&per_page=${params.per_page}&page=${params.page}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }
  )).json()
}

// 获取个人律动数据
export const getTeacherMusicRhythm = async (json_url: string) => {
  const res = await fetch(json_url)
  return await res.json()
}
