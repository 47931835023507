import { forEach } from 'lodash'
import { useStore } from 'vuex'
import { TreeItemType, dataPermission } from './config.js'

export const bindNodeResourceId = (list: any[], node: any, resoucreId: any) => {
  const currentList: any[] = []
  list.forEach((item: any) => {
    const tmpNode: any = {
      name: item.name,
      type: item.type,
      id: item.id
    }
    if (tmpNode.type === TreeItemType.Chapter) {
      const childList = bindNodeResourceId(item.children || [], node, resoucreId)
      tmpNode.children = childList
    } else if (tmpNode.type === TreeItemType.Periods) {
      if (tmpNode.id === node.id) {
        tmpNode.resource_id = resoucreId
      } else {
        tmpNode.resource_id = item.resource_id
      }
    }
    currentList.push(tmpNode)
  })
  return currentList
}

export const filterParams = (list: any[], params: any) => {
  list.forEach(el => {
    const newNode: any = {
      name: el.name,
      type: el.type
    }
    if (newNode.type === TreeItemType.Chapter) {
      newNode.children = []
      filterParams(el.children || [], newNode.children)
    } else if (newNode.type === TreeItemType.Periods) {
      newNode.resource_id = el.resource_id
    }

    newNode.id = el.id >= 0 ? el.id : null
    params.push(newNode)
  })
}

export const queryChild = (list: any[], childId: number) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.id === childId) {
      return item
    } else {
      const childHas = queryChild(item.children || [], childId)
      if (childHas !== undefined) {
        return childHas
      }
    }
  }
  return undefined
}

// 根据索引值获取指定节点下的某个子节点
export const queryChildByIndex = (list: any[], parentNode: any, index: number) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.id === parentNode.id) {
      return item.children[index]
    } else {
      const childrenNode = queryChildByIndex(item.children || [], parentNode, index)
      if (childrenNode !== undefined) {
        return childrenNode
      }
    }
  }
  return undefined
}

// 用于增加一个子节点
export const addChildNode = (list: any[], node: any, parentId: number) => {
  const currentList: any[] = []
  const newList = [...list]
  newList.forEach(item => {
    const newNode = { ...item }
    if (newNode.type === TreeItemType.Chapter) {
      const childList = addChildNode(item.children || [], node, parentId)
      newNode.children = childList
    }
    if (newNode.id === parentId) {
      newNode.children.push(node)
    }
    currentList.push(newNode)
  })
  return currentList
}

// 用于更新课时节点新增的时候更新名字
export const editNode = (list: any[], node: any) => {
  const currentList: any[] = []
  const newList = [...list]
  newList.forEach(item => {
    const newNode = { ...item }
    if (newNode.type === TreeItemType.Chapter) {
      newNode.children = editNode(item.children || [], node)
    }
    if (newNode.id === node.id) {
      newNode.name = node.name
      newNode.type = node.type
    }
    currentList.push(newNode)
  })
  return currentList
}

// 删除子节点
export const deleteChild = (list: any[], node: any) => {
  const currentList: any[] = []
  const newList = [...list]
  newList.forEach(item => {
    const newNode = { ...item }
    if (newNode.type === TreeItemType.Chapter) {
      newNode.children = deleteChild(item.children || [], node)
    }
    if (newNode.id !== node.id) {
      currentList.push(newNode)
    }
  })
  return currentList
}

// 检索某个节点
export const queryNode = (list: any[], nodeId: number) => {
  const newList = [...list]
  for (let i = 0; i < newList.length; i++) {
    const item = list[i]
    if (item.id === nodeId) {
      return item
    }
    if (item.type === TreeItemType.Chapter) {
      const data = queryNode(item.children || [], nodeId)
      if (data !== undefined) {
        return data
      }
    }
  }
  return undefined
}

// 获取当前用户可以读取的资源类型
export const getInfoPermissionFromUser = () => {
  const store = useStore()
  const userType = store.state.mainAppName
  const list = dataPermission.filter((item: { type: string }) => item.type === userType)
  if (list.length < 1) {
    return []
  }
  return list[0].permissions
}

export const hasPermission = (key) => {
  const store = useStore()
  const userType = store.state.mainAppName
  let list = dataPermission.filter((item: { type: string }) => item.type === userType)
  if (list.length < 1) {
    list = []
  }
  return new Set(list).has(key)
}

export const getAllChapterNode = (list: any[], result: any[]) => {
  list.forEach((item: { type: any; id: any, children: any[] }) => {
    if (item.type === TreeItemType.Chapter) {
      result.push(item.id)
      getAllChapterNode(item.children || [], result)
    }
  })
}

export const getFirstPeriodsPPT = (list: any[], path: any[] = []) => {
  let result
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    if (item.type === TreeItemType.Periods) {
      return item
    }

    if (item.type === TreeItemType.Chapter) {
      path.push(item.id)
      result = getFirstPeriodsPPT(item.children || [], path)
      if (result === undefined) {
        path.pop()
      } else {
        return result
      }
    }
  }
  return undefined
}

// 子节点上移
export const moveChildItem = (list:any[], node:any, type = 'up') => {
  const newList: any[] = []
  let inChild = false
  list.forEach((currentNode: any) => {
    // 章节节点
    if (currentNode.type === TreeItemType.Chapter) {
      const childQuery = moveChildItem(currentNode.children || [], node, type)
      // 检索子节点
      currentNode.children = childQuery.childList
      // 如果在自己的子节点下
      if (childQuery.inChild) {
        const childList = currentNode.children
        let index = 0
        for (let i = 0; i < childList.length; i++) {
          if (node.id === childList[i].id) {
            index = i
            break
          }
        }
        // 上移
        if (type === 'up' && index !== 0) {
          const tmp = childList[index]
          childList[index] = childList[index - 1]
          childList[index - 1] = tmp
          currentNode.children = childList
        } else if (type === 'down' && index !== childList.length - 1) {
          // 下移
          const tmp = childList[index]
          childList[index] = childList[index + 1]
          childList[index + 1] = tmp
          currentNode.children = childList
        }
      }
    }
    if (node.id === currentNode.id) {
      inChild = true
    }
    newList.push(currentNode)
  })
  return { childList: newList, inChild }
}
