<template>
  <div class="noselect">
    <span
      :style="
        isSelect && { color: store.state.themeColor, fontWeight: 'bolder' }
      "
      @click="handleSelect"
      class="title_span"
      :title="data.name"
    >
      <span class="chapter_icon">
        <DownOutlined v-if="selectKeysComputed.indexOf(data.id) > -1" />
        <RightOutlined v-else
      /></span>
      {{ data.name }}
    </span>
    <span class="opt_span">
      <a-dropdown :trigger="['click']" v-model:visible="optVisible">
        <EllipsisOutlined
          :style="
            optVisible && {
              color: store.state.themeColor,
              fontWeight: 'bloder',
            }
          "
        />
        <template #overlay>
          <a-menu>
            <a-menu-item @click="handleEdit">
              <EditOutlined />
              <span type="primary" class="options-btn">编辑</span>
            </a-menu-item>
            <a-popconfirm
              title="是否确认删除该章节？删除后章节下的所有课时、章节都将删除"
              ok-text="确定"
              cancelText="取消"
              @confirm="handleDelete"
            >
              <a-menu-item><DeleteOutlined />删除</a-menu-item>
            </a-popconfirm>
            <a-menu-item v-if="canMoveUp" @click="() => handleMoveItem('up')"><ArrowUpOutlined />上移</a-menu-item>
            <a-menu-item v-if="canMoveDown" @click="() => handleMoveItem('down')"><ArrowDownOutlined />下移</a-menu-item>
            <a-menu-item @click="handleContactCourseware"><ColumnWidthOutlined />关联资源</a-menu-item>
            <a-menu-item @click="handleShowMoreCoursewares">查看关联课件</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </span>
  </div>
</template>

<script>
import { computed, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import { deleteChild, moveChildItem, queryChild } from './commonUtil'
import { DownOutlined, RightOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined, ColumnWidthOutlined } from '@ant-design/icons-vue'
import { TreeItemType } from './config'

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    selectKeys: {
      type: Array,
      default: () => { return [] }
    },
    currentSelect: {
      type: Object,
      default: () => { return { id: -1 } }
    },
    allList: {
      type: Array,
      default: () => { return [] }
    },
    canEdit: {
      type: Boolean,
      default: () => { return false }
    },
    moveUp: {
      type: Boolean,
      default: () => { return false }
    },
    moveDown: {
      type: Boolean,
      default: () => { return false }
    }
  },
  components: {
    DownOutlined,
    RightOutlined,
    EditOutlined,
    EllipsisOutlined,
    DeleteOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    ColumnWidthOutlined
  },
  emits: [
    'hidenChildren',
    'showChildren',
    'updateCurrentSelect',
    'update:selectKeys',
    'showMask',
    'onDelete',
    'onEdit',
    'moveChildItem'
  ],
  setup (props, { emit }) {
    // 当前节点数据
    const data = computed(() => props.item)
    // 打开的节点集合
    const selectKeysValue = reactive(props.selectKeys)
    // 当前选中的节点
    const currentSelect = computed(() => props.currentSelect)
    // 当前需要展开显示子节点的集合
    const selectKeysComputed = computed(() => props.selectKeys)
    const store = useStore()
    // 是否选中了当前节点
    const isSelect = computed(() => {
      return currentSelect.value.id === data.value.id
    })
    // 所有的数据
    const list = computed(() => props.allList)
    // 是否可以编辑
    const canEditValue = ref(props.canEdit)

    const optVisible = ref(false)

    const canMoveUp = computed(() => props.moveUp)
    const canMoveDown = computed(() => props.moveDown)

    // 隐藏子节点
    const handleHideChildren = item => {
      emit('hidenChildren', item)
    }

    // 展示子节点
    const handleShowChildren = item => {
      emit('showChildren', item)
    }

    // 点击当前节点
    const handleSelect = () => {
      // 如果当前节点为选中高亮的节点， 取消选中
      if (currentSelect.value.id === data.value.id) {
        emit('updateCurrentSelect', -1)
        handleHideChildren(data.value)
      } else {
        // 如果当前节点不是选中高亮的节点
        // 判断该节点是否已经展开了，没有的话，给展开集合加入该节点
        const index = selectKeysValue.indexOf(data.value.id)
        if (index <= -1) {
          selectKeysValue.push(data.value.id)
          emit('update:selectKeys', selectKeysValue)
          // 更新选中信息
          emit('updateCurrentSelect', data.value.id)
        } else {
          handleHideChildren(data.value)
        }
      }
    }

    // 删除节点
    const handleDelete = () => {
      optVisible.value = false
      if (store.state.currentPeriods) {
        // 当前打开的课时节点在要删除的节点下，需要关闭
        const childData = queryChild(data.value.children || [], store.state.currentPeriods.id)

        if (childData !== undefined) {
          emit('showMask')
          store.commit('setCurrentPeriods', undefined)
        }
      }
      // 更新新的树状信息
      const newTreeList = deleteChild(list.value, data.value)
      emit('onDelete', newTreeList)
    }

    // 编辑节点
    const handleEdit = () => {
      optVisible.value = false
      emit('onEdit', data.value)
    }

    const handleAddModal = () => {
      emit('onCreate')
    }

    const handleMoveItem = (type = 'up') => {
      const trees = [{ id: -1, name: '根节点', type: TreeItemType.Chapter, children: [...toRaw(list.value)] }]
      const newTrees = moveChildItem(trees, data.value, type)
      const params = newTrees.childList[0]
      optVisible.value = false
      emit('moveChildItem', params?.children)
    }

    const handleContactCourseware = () => {
      optVisible.value = false
      emit('onContractCourseware', { book_id: data.value.book_id, book_section_id: data.value.id })
    }

    const handleShowMoreCoursewares = () => {
      // console.log(data.value); return
      // eslint-disable-next-line no-unreachable
      emit('onShowMoreCoursewares', {
        book_id: data.value.book_id,
        book_name: data.value.name,
        book_section_id: data.value.id
      })
    }

    return {
      data,
      selectKeysValue,
      isSelect,
      store,
      canEditValue,
      selectKeysComputed,
      optVisible,
      canMoveUp,
      canMoveDown,
      handleEdit,
      handleSelect,
      handleDelete,
      handleHideChildren,
      handleShowChildren,
      handleAddModal,
      handleMoveItem,
      handleContactCourseware,
      handleShowMoreCoursewares
    }
  }
}
</script>

<style lang="less">
.noselect {
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hasSelect {
  background-color: aquamarine;
}

.option {
  margin-left: 10px;
}

.options-btn {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title_span {
  padding: 2px 5px;
  width: 70%;
  overflow: hidden; //超出一行文字自动隐藏
  text-overflow: ellipsis; //文字隐藏后添加省略号
  white-space: nowrap; //强制不换行
  display: inline-block;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
}

.chapter_icon {
  font-size: 10px;
  margin-right: 2px;
}

.ant-popover {
  z-index: 2000;
}

.opt_span {
  vertical-align: middle;
}
</style>
