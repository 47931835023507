<template>
  <a-modal
    title="绑定教材"
    :visible="visibleProp"
    :maskClosable="false"
    :footer="null"
    @cancel="handleCancel"
    destroyOnClose
    :width="1000"
  >
    <div>
      <a-form layout="inline">
        <a-form-item label="名称" :name="name">
          <a-input v-model:value="formData.name" @keydown.enter="handleSearch"/>
        </a-form-item>
        <a-form-item label="教材">
          <a-select
            v-model:value="formData.isCurrentBook"
            :options="selectOptions"
          />
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button type="primary" @click="handleSearch">搜索</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-tabs v-model:activeKey="activeKey" @change="handleTabChange">
      <a-tab-pane v-for="tab in tabList" :tab="tab.label" :key="tab.value">
      </a-tab-pane>
    </a-tabs>
    <!-- 谱例歌曲的 -->
    <a-table
      size="small"
      v-if="activeKey === tabTypeMap.SHEET"
      :dataSource="list"
      :columns="sheetSongColumns"
      :pagination="paginations"
      :scroll="{ x: 1400 }"
      rowKey="id"
      :loading="loading"
    >
      <template #songtype="{ text }">
        <span>{{ SheetSongTypeForContact[text] }}</span>
      </template>
      <template #tags="{ text }">
        <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
      </template>
      <template #actions="{ record }">
        <a-button
          size="small"
          type="warning"
          @click="() => handleRemove(record)"
          v-if="
            record.book_id === bookInfoProp.book_id &&
            record.book_section_id === bookInfoProp.book_section_id
          "
        >
          取消关联
        </a-button>
        <a-button
          size="small"
          type="primary"
          @click="() => handleAdd(record)"
          v-else
        >
          关联
        </a-button>
      </template>
    </a-table>
    <a-table
      size="small"
      v-if="activeKey === tabTypeMap.ENJOY"
      :dataSource="list"
      :columns="enjoySongColumns"
      :pagination="paginations"
      :scroll="{ x: 1300 }"
      rowKey="id"
      :loading="loading"
    >
      <template #tags="{ text }">
        <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
      </template>
      <template #actions="{ record }">
        <a-button
          size="small"
          type="warning"
          @click="() => handleRemove(record)"
          v-if="
            record.book_id === bookInfoProp.book_id &&
            record.book_section_id === bookInfoProp.book_section_id
          "
        >
          取消关联
        </a-button>
        <a-button
          size="small"
          type="primary"
          @click="() => handleAdd(record)"
          v-else
        >
          关联
        </a-button>
      </template>
    </a-table>
    <a-table
      size="small"
      v-if="activeKey === tabTypeMap.COURSEWARE"
      :dataSource="list"
      :columns="coursewareColumns"
      :pagination="paginations"
      :scroll="{ x: 1400 }"
      rowKey="id"
      :loading="loading"
    >
      <template #coursewareType="{text}">
        {{coursewareTypeMap[text]?.name || '--'}}
      </template>
      <template #tags="{ text }">
        <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
      </template>
      <template #actions="{ record }">
        <a-button
          size="small"
          type="warning"
          @click="() => handleRemove(record)"
          v-if="
            record.book_id === bookInfoProp.book_id &&
            record.book_section_id === bookInfoProp.book_section_id
          "
        >
          取消关联
        </a-button>
        <a-button
          size="small"
          type="primary"
          @click="() => handleAdd(record)"
          v-else
        >
          关联
        </a-button>
      </template>
      <template #status="{ text }">
        <span>{{ statusMap[text].name }}</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { computed, onMounted, ref, toRaw } from 'vue'
import * as service from '../service'
import { SheetSongTypeForContact } from './config'
import { statusMap, coursewareTypeMap } from '@/utils/dataMap'
import { message } from 'ant-design-vue'

export default {
  emits: ['onOk', 'onCancel'],
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    bookInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const visibleProp = computed(() => props.visible)

    const activeKey = ref('sheet')

    const formData = ref({
      isCurrentBook: true
    })

    const selectOptions = ref([
      { label: '当前教材', value: true },
      { label: '全部教材', value: false }
    ])

    const list = ref([])

    const bookInfoProp = computed(() => props.bookInfo)

    const loading = ref(false)

    let searchFunc = service.getOfficialSheetSong

    const sheetSongColumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '歌曲编码',
        dataIndex: 'song_code',
        key: 'song_code',
        width: 80,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        key: 'py',
        ellipsis: true,
        width: 50
      },
      {
        title: '歌曲类型',
        dataIndex: 'song_type',
        key: 'song_type',
        slots: { customRender: 'songtype' },
        width: 50
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        width: 100,
        ellipsis: true
      },
      {
        title: '关联教材',
        dataIndex: 'book.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材章节',
        dataIndex: 'section.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '操作',
        key: 'actions',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]

    const enjoySongColumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '歌曲编码',
        dataIndex: 'song_code',
        key: 'song_code',
        width: 80,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        key: 'py',
        ellipsis: true,
        width: 50
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        width: 100,
        ellipsis: true
      },
      {
        title: '关联教材',
        dataIndex: 'book.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材章节',
        dataIndex: 'section.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]

    const coursewareColumns = [
      {
        title: '课件名称',
        dataIndex: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '课件类型',
        dataIndex: 'type',
        ellipsis: true,
        width: 80,
        slots: { customRender: 'coursewareType' }
      },
      {
        title: '教材',
        dataIndex: 'book.name',
        width: 80,
        ellipsis: true
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '章节',
        dataIndex: 'section_breadcrumb',
        width: 80,
        ellipsis: true
      },
      {
        title: '制作者',
        dataIndex: 'author_name',
        width: 80,
        ellipsis: true
      },
      {
        title: '标签',
        dataIndex: 'tags',
        ellipsis: true,
        width: 80,
        slots: { customRender: 'tags' }
      },
      {
        title: '状态',
        dataIndex: 'status',
        ellipsis: true,
        width: 80,
        slots: { customRender: 'status' }
      },
      {
        title: '创建时间',
        dataIndex: 'created_at',
        width: 80,
        ellipsis: true
      },
      {
        title: '修改时间',
        dataIndex: 'updated_at',
        width: 80,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]

    const tabTypeMap = {
      SHEET: 'sheet',
      ENJOY: 'enjoy',
      COURSEWARE: 'courseware'
    }

    const tabList = [
      {
        label: '谱例歌曲',
        value: tabTypeMap.SHEET
      },
      {
        label: '欣赏歌曲',
        value: tabTypeMap.ENJOY
      },
      {
        label: '课件',
        value: tabTypeMap.COURSEWARE
      }
    ]

    let currentPage = 1
    const handlePageChange = (page) => {
      currentPage = page
      handleSearch()
    }

    const paginations = ref({ current: currentPage, pageSize: 10, onChange: handlePageChange })

    const handleSearch = async () => {
      try {
        loading.value = true
        const params = {
          book_id: bookInfoProp.value.book_id,
          ...toRaw(formData.value),
          page: currentPage,
          per_page: 10
        }
        if (!formData.value.isCurrentBook) {
          delete params.book_id
        }
        const data = await searchFunc(params)
        const { items, meta } = data
        list.value = items
        paginations.value = {
          ...toRaw(paginations.value),
          ...meta,
          pageSize: +meta.per_page,
          current: meta.current_page
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        list.value = []
        console.log(error)
      }
    }

    const handleReset = () => {
      formData.value.isCurrentBook = true
      formData.value.name = ''
      currentPage = 1
      paginations.value = { current: 1, pageSize: 10, onChange: handlePageChange }
      handleSearch()
    }

    const handleCancel = () => {
      emit('onCancel')
    }

    const handleTabChange = () => {
      currentPage = 1
      switch (activeKey.value) {
      case tabTypeMap.SHEET:
        searchFunc = service.getOfficialSheetSong
        break
      case tabTypeMap.ENJOY:
        searchFunc = service.getOfficialEnjoySong
        break
      case tabTypeMap.COURSEWARE:
        searchFunc = service.getOfficialCourseware
        break
      default:
        searchFunc = service.getOfficialSheetSong
      }
      handleReset()
    }

    const constructparams = (data) => {
      let params = null
      switch (activeKey.value) {
      case tabTypeMap.SHEET:
        params = {
          name: data.name,
          py: data.py,
          song_type: data.song_type,
          sheet_type: data.sheet_type,
          speed: data.speed,
          picture_url: data.picture_url,
          evxml_file_url: data.evxml_file_url,
          evxml_file_md5: data.evxml_file_md5,
          evxml_file_size: data.evxml_file_size,
          ori_file_url: data.ori_file_url,
          ori_file_md5: data.ori_file_md5,
          ori_file_size: data.ori_file_size,
          acc_file_url: data.acc_file_url,
          acc_file_md5: data.acc_file_md5,
          acc_file_size: data.acc_file_size,
          acc_speed: data.acc_speed,
          book_id: bookInfoProp.value.book_id,
          book_section_id: bookInfoProp.value.book_section_id
        }
        break
      case tabTypeMap.ENJOY:
        params = {
          name: data.name,
          py: data.py,
          picture_url: data.picture_url ? data.picture_url : [],
          mp3_file_config: data.mp3_file_config,
          book_id: bookInfoProp.value.book_id,
          book_section_id: bookInfoProp.value.book_section_id
        }
        break
      case tabTypeMap.COURSEWARE:
        params = {
          name: data.name,
          type: data.type,
          size: data.size,
          url: data.url,
          md5: data.md5,
          author_type: data.author_type,
          share_mode: data.share_mode,
          book_id: bookInfoProp.value.book_id,
          book_section_id: bookInfoProp.value.book_section_id
        }
        break
      }
      return params
    }

    const handleAdd = async (data) => {
      console.log(data)
      const params = constructparams(data)
      let requestFunc = null
      if (activeKey.value === tabTypeMap.SHEET) {
        requestFunc = service.editOfficialSheetSong
      }
      if (activeKey.value === tabTypeMap.ENJOY) {
        requestFunc = service.editOfficialEnjoySong
      }
      if (activeKey.value === tabTypeMap.COURSEWARE) {
        requestFunc = service.editOfficialCourseware
      }
      await requestFunc(data.id, params)
      message.success(`${data.name}关联成功`)
      await handleSearch()
    }

    const handleRemove = async (data) => {
      const params = constructparams(data)
      params.book_section_id = 0
      let requestFunc = null
      if (activeKey.value === tabTypeMap.SHEET) {
        requestFunc = service.editOfficialSheetSong
      }
      if (activeKey.value === tabTypeMap.ENJOY) {
        requestFunc = service.editOfficialEnjoySong
      }
      if (activeKey.value === tabTypeMap.COURSEWARE) {
        requestFunc = service.editOfficialCourseware
      }
      await requestFunc(data.id, params)
      message.success(`${data.name}取消关联成功`)
      await handleSearch()
    }

    onMounted(() => {
      console.log(bookInfoProp.value)
      handleSearch()
    })

    return {
      selectOptions,
      visibleProp,
      tabList,
      list,
      activeKey,
      formData,
      tabTypeMap,
      sheetSongColumns,
      enjoySongColumns,
      coursewareColumns,
      paginations,
      statusMap,
      SheetSongTypeForContact,
      bookInfoProp,
      loading,
      coursewareTypeMap: coursewareTypeMap,
      handleCancel,
      handleTabChange,
      handleReset,
      handleSearch,
      handleAdd,
      handleRemove
    }
  }
}
</script>

<style>
</style>
