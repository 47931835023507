<template>
  <div>
    <a-modal
      v-model:visible="visibleValue"
      :footer="null"
      :title="modalActionType.Edit === actionType ? '编辑' : '新建'"
      @cancel="handleCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <a-form
        :model="formData"
        ref="formRef"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item
          label="标题"
          name="name"
          :rules="{ required: true, message: '请输入名称' }"
        >
          <a-input v-model:value="formData.name" autocomplete="off" />
        </a-form-item>
        <a-form-item
          label="类型"
          name="type"
          :rules="{ required: true, message: '请选择类型' }"
        >
          <a-select
            :disabled="!canChange"
            v-model:value="formData.type"
            :options="TypeSelectMap"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
          <a-button
            type="primary"
            htmlType="submit"
            @click="onSubmit"
            :loading="loadingValue"
          >
            保存
          </a-button>
          <a-button
            style="margin-left: 10px"
            @click="handleCancel"
            :disabled="loadingValue"
          >
            取消
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, toRaw, toRef } from 'vue'
import { modalActionType, TreeItemType, TypeSelectMap } from './config'
import { addChildNode, editNode } from './commonUtil'
export default {
  props: {
    // 当前选中的节点
    item: {
      type: Object,
      default: () => { return {} }
    },
    actionType: {
      type: String,
      default: () => { return modalActionType.Create }
    },
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    loading: {
      type: Boolean,
      default: () => { return false }
    },
    allList: {
      type: Array,
      default: () => { return [] }
    }
  },
  emits: ['onChange', 'onCancel'],
  setup (props, { emit }) {
    // 当前选中的节点
    const parentNode = computed(() => props.item)
    // 表单数据
    const formData = reactive({ name: undefined, type: TreeItemType.Periods })
    // 弹框是否可见
    const visibleValue = toRef(props, 'visible')
    // 表单类型
    const actionTypeValue = toRaw(props.actionType)
    // 完整的树状信息
    const allList = computed(() => props.allList)
    // 表单的ref
    const formRef = ref()
    // 等待状态
    const loadingValue = computed(() => props.loading)

    // 编辑的状态下初始化数据
    if (actionTypeValue === modalActionType.Edit) {
      formData.name = parentNode.value.name
      formData.type = parentNode.value.type
    }

    // 表单样式
    const labelCol = { span: 6 }
    const wrapperCol = { span: 14 }

    // 是否可以修改节点类型
    const canChange = computed(() =>
      actionTypeValue === modalActionType.Create ||
      (
        parentNode.value.type === TreeItemType.Chapter &&
        (!parentNode.value.children || parentNode.value.children.length < 1)
      ) ||
      actionTypeValue === modalActionType.CreateRootNode
    )

    // 构造新的临时节点
    const constructNewNode = (info) => {
      // 构建新的节点，id默认先给个负数的，防止没有
      const newNode = {
        id: -1 * new Date().getTime(),
        name: info.name,
        type: info.type
      }
      if (newNode.type === TreeItemType.Chapter) {
        newNode.children = []
      } else if (newNode.type === TreeItemType.Periods) {
        newNode.resouce_id = ''
      }
      return newNode
    }

    // 增加节点的处理方法,构造完整的树状数据返回
    const handleAddChildNode = (treeList, parentId) => {
      const newNode = constructNewNode(formData)
      const newTreeList = addChildNode(treeList, newNode, parentId)
      return { newTreeList, newNode }
    }

    // 构造编辑后的树信息
    const handleEditNode = (treeList) => {
      // 更新节点类型
      const newNode = { ...toRaw(parentNode.value), name: formData.name, type: formData.type }
      const newTreeList = editNode(treeList, newNode)
      return { newTreeList, newNode }
    }

    // 表单提交
    const onSubmit = () => {
      formRef.value.validate().then(() => {
        // 根节点下增加节点
        if (actionTypeValue === modalActionType.CreateRootNode) {
          const rootList = [
            {
              id: -1,
              name: '根节点',
              type: TreeItemType.Chapter,
              children: [...toRaw(allList.value)]
            }
          ]
          const { newTreeList, newNode } = handleAddChildNode(rootList, -1)
          const rootNode = newTreeList[0]
          // 通知父组件去更新
          emit('onChange', rootNode.children || [], newNode, modalActionType.CreateRootNode, newNode)
        }

        // 非根节点下增加节点
        if (actionTypeValue === modalActionType.Create) {
          const { newTreeList, newNode } = handleAddChildNode(allList.value, parentNode.value.id)
          // 通知父组件去更新
          emit('onChange', newTreeList, newNode, modalActionType.Create, parentNode.value)
        }
        // 编辑节点
        if (actionTypeValue === modalActionType.Edit) {
          const { newTreeList, newNode } = handleEditNode(allList.value)
          emit('onChange', newTreeList, newNode, modalActionType.Edit)
        }
      }).catch(error => {
        console.log('error', error)
      })
    }

    // 通知父组件关闭
    const handleCancel = () => {
      emit('onCancel')
    }

    return {
      parentNode,
      formData,
      formRef,
      visibleValue,
      actionTypeValue,
      TypeSelectMap,
      modalActionType,
      labelCol,
      wrapperCol,
      loadingValue,
      canChange,
      onSubmit,
      handleCancel
    }
  }

}
</script>

<style>
</style>
