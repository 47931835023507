export const ActionType = {
  EDIT: 'edit',
  PREVIEW: 'preview'
}

export const ResourceType = {
  OFFICIAL: 'official',
  TEACHER: 'teacher'
}

// 弹窗
export const SongModalType = {
  SHEET: 'sheet',
  ENJOY: 'enjoy',
  RHYTHM: 'rhythm',
  QUESTIONS: 'questions',
  QGROUP: 'qGroup'
}

export const dataPermission = [
  {
    type: 'shenyue',
    permissions: ['official']
  },
  {
    type: 'schoolPlatform',
    permissions: ['official', 'teacher']
  }
]

export const tabKeyName = {
  official: '官方',
  teacher: '我的'
}

export const SheetSongType = {
  0: '演唱歌曲',
  2: '合唱歌曲',
  3: '多声部合唱',
  4: '钢琴歌曲'
}

export const SheetTypeOptions = [
  {
    value: 0,
    label: '演唱歌曲'
  },
  {
    value: 2,
    label: '合唱歌曲'
  },
  {
    value: 3,
    label: '多声部合唱'
  }
  // {
  //   value: 4,
  //   label: '钢琴歌曲'
  // }
]

// 律动类型
export const MusicRhythmType = {
  0: '下架',
  1: '上架',
  2: '试点'
}
export const difficultyType = {
  0: '简单',
  1: '中等',
  2: '困难'
}
export const DifficultyType = [
  {
    label: '全部',
    value: -1
  },
  {
    label: '简单',
    value: 0
  },
  {
    label: '中等',
    value: 1
  },
  {
    label: '困难',
    value: 2
  }
]

export const questionType = {
  1: '单选题',
  2: '多选题',
  3: '编创题',
  4: '判断题',
  5: '演唱题',
  6: '演奏题',
  7: '视唱题',
  8: '连线题',
  9: '填空题',
  10: '简答题',
  11: '套题',
  12: '拖拽题',
  13: '色环题',
  14: '拼图题'
}
export const QuestionTypeOptions = [
  {
    label: '单选题',
    value: 1
  },
  {
    label: '多选题',
    value: 2
  },
  {
    label: '编创题',
    value: 3
  },
  {
    label: '判断题',
    value: 4
  },
  // {
  //   label: '演唱题',
  //   value: 5
  // },
  // {
  //   label: '演奏题',
  //   value: 6
  // },
  // {
  //   label: '视唱题',
  //   value: 7
  // },
  {
    label: '连线题',
    value: 8
  },
  {
    label: '填空题',
    value: 9
  },
  {
    label: '简答题',
    value: 10
  },
  {
    label: '套题',
    value: 11
  },
  {
    label: '拖拽题',
    value: 12
  },
  // {
  //   label: '色环题',
  //   value: 13
  // },
  {
    label: '拼图题',
    value: 14
  }
]

export const MainAppNameMap = {
  SHENYUE: 'shenyue',
  TEACHER: 'schoolPlatform',
  INS: 'institutionPlatform'
}

/**
 * PPT编辑模式启动参数配置
 */
export const EditPptModeRuntimeConfig = {
  uploadMode: 'cloud',
  state: {
    screening: false,
    screenType: 'edit-check',
    editMode: 'normal'
  },
  isSavePpt: true,
  isShowDocument: true,
  importExportControl: {
    importJson: true,
    exportJson: true
  },
  playPptRule: {
    play: 'demonstrate',
    stop: 'edit-check'
  },
  dynamicEditorTabs: [
    {
      id: 100,
      name: '教案',
      parentId: 0,
      tabType: 'lessonPlan',
      keepAlive: true
    },
    {
      id: 101,
      name: '说课',
      parentId: 0,
      tabType: 'speaklesson',
      keepAlive: true
    }
  ],
  extData: {}
}

/**
 * 禁止导出的PPT编辑模式启动参数配置1
 */
export const EditPptModeRuntimeConfigWithoutExport = {
  uploadMode: 'cloud',
  state: {
    screening: false,
    screenType: 'edit-check',
    editMode: 'normal'
  },
  isSavePpt: true,
  isShowDocument: false,
  importExportControl: {
    importJson: true,
    exportJson: false
  },
  playPptRule: {
    play: 'demonstrate',
    stop: 'edit-check'
  },
  dynamicEditorTabs: [
    {
      id: 100,
      name: '教案',
      parentId: 0,
      tabType: 'lessonPlan',
      keepAlive: true
    },
    {
      id: 101,
      name: '说课',
      parentId: 0,
      tabType: 'speaklesson',
      keepAlive: true
    }
  ],
  extData: {
    coursewareId: 1,
    coursewareSource: 'official'
  }
}

/**
 * 预览PPT模式启动参数
 */
export const PreviewPptModeRuntimeConfig = {
  state: {
    screening: true,
    screenType: 'preview',
    editMode: 'normal'
  },
  isSavePpt: false,
  playPptRule: {
    play: 'demonstrate',
    stop: 'preview'
  },
  dynamicPreviewerTabs: [
    {
      id: 1,
      name: '教案',
      parentId: 0,
      tabType: 'lessonPlan',
      keepAlive: true
    },
    {
      id: 2,
      name: '说课',
      parentId: 0,
      tabType: 'speaklesson',
      keepAlive: true
    }
  ],
  extData: {
    coursewareId: 0,
    coursewareSource: 'official'
  }
}

/**
 * 编辑知识点模式启动参数
 */
export const EditKnowledgeModeRuntimeConfig = {
  uploadMode: 'cloud',
  state: {
    screening: false,
    screenType: 'edit-check',
    editMode: 'normal'
  },
  isSavePpt: true,
  importExportControl: {
    importJson: true,
    importPpt: true
  },
  playPptRule: {
    play: 'demonstrate',
    stop: 'edit-check'
  }
}

/**
 * 禁用导出的编辑知识点模式启动参数
 */
export const EditKnowledgeModeRuntimeConfigWithoutExport = {
  uploadMode: 'cloud',
  state: {
    screening: false,
    screenType: 'edit-check',
    editMode: 'knowledgePoints'
  },
  isSavePpt: true,
  importExportControl: {
    importJson: true,
    exportJson: false
  },
  playPptRule: {
    play: 'demonstrate',
    stop: 'edit-check'
  }
}

/**
 * 知识点预览模式启动参数配置
 */
export const PreviewKnowledgeModeRuntimeConfig = {
  state: {
    screening: true,
    screenType: 'preview',
    editMode: 'knowledgePoints'
  },
  isSavePpt: false,
  playPptRule: {
    play: 'demonstrate',
    stop: 'preview'
  }
}
