import { getRequest, postRequest, deleteRequest } from '@/utils/http'

export async function getCoursewareList (params) {
  return getRequest('/official/coursewares', params)
}

export async function getCoursewareVersionList (params) {
  return getRequest('/official/courseware-versions', params)
}

export async function createCourseware (params) {
  return postRequest('/official/courseware/create', params)
}

export async function editCourseware (id, params) {
  return postRequest(`/official/courseware/${id}/edit`, params)
}

export async function deleteCourseware (id) {
  return deleteRequest(`/official/courseware/${id}/delete`)
}

export async function getCoursewareInfo (id) {
  return getRequest(`/official/courseware/${id}`)
}

export async function getCoursewareVersionInfo (id) {
  return getRequest(`/official/courseware-version/${id}`)
}

export async function setCoursewareVersionTest (id) {
  return postRequest(`/official/courseware-version/${id}/set-test`)
}
export async function setCoursewareVersionProduction (id) {
  return postRequest(`/official/courseware-version/${id}/set-production`)
}
export async function setCoursewareVersionDevelop (id) {
  return postRequest(`/official/courseware-version/${id}/set-develop`)
}
export async function deleteCoursewareVersion (id) {
  return deleteRequest(`/official/courseware-version/${id}/delete`)
}
export async function setCoursewareStatus (id, params) {
  return postRequest(`/official/courseware/${id}/set-status`, params)
}
export async function batchSetCoursewareProduction (params) {
  return postRequest('/official/courseware/batch-set-production', params)
}
export async function batchSetCoursewareTest (params) {
  return postRequest('/official/courseware/batch-set-test', params)
}
export async function setCoursewareTags (id, params) {
  return postRequest(`/official/courseware/${id}/set-tags`, params)
}
export async function getCoursewareDevelopVersion (id) {
  return getRequest(`/official/courseware/${id}/get-develop-version`)
}
export async function getRelatePPTCoursewaresList (id, params) {
  return getRequest(`/official/courseware/${id}/relatePPTCoursewares`, params)
}
export async function getRelateSpeakLessonCoursewaresList (id, params) {
  return getRequest(`/official/courseware/${id}/relateSpeakLessonCoursewares`, params)
}
export async function getRelateTeachPlanCoursewaresList (id, params) {
  return getRequest(`/official/courseware/${id}/relateTeachPlanCoursewares`, params)
}
