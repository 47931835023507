<template>
  <a-modal
    :width="1000"
    :maskClosable="false"
    title="谱例歌曲列表"
    :visible="visibleValue"
    @cancel="handleCancel"
    class="modal_style"
    :footer="null"
    :destroyOnClose="true"
    style="top: 10px"
  >
    <a-form layout="inline">
      <a-row style="width: 100%" :gutter="[16, 16]">
        <a-col :md="8" :sm="24">
          <a-form-item label="歌曲名称">
            <a-input
              v-model:value="formData.name"
              placeholder="请输入歌曲名称"
              @keydown.enter="handleSearch"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="歌曲编码">
            <a-input
              v-model:value="formData.song_code"
              placeholder="请输入歌曲编码"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="歌曲类型">
            <a-select
              :allowClear="true"
              v-model:value="formData.song_type"
              :options="SheetTypeOptions"
              placeholder="请选择歌曲类型"
            ></a-select>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="标签">
            <a-select
              v-model:value="formData.tag"
              mode="multiple"
              placeholder="可检索标签"
              style="width: 100%"
              :options="tagOpts"
              @search="getTagSearch"
            >
              <template #suffixIcon><search-outlined /></template>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="12">
          <a-form-item label="所属教材章节">
            <a
              v-if="bookShowName.length < 20"
              href="#"
              @click="handleShowBookModal"
            >
              {{ bookShowName }}
            </a>
            <a v-else href="#" @click="handleShowBookModal">
              <a-tooltip :title="bookShowName">
                {{ bookShowName.slice(0, 20) }}...
              </a-tooltip>
            </a>
          </a-form-item>
        </a-col>
        <a-col :md="4">
          <a-space>
            <a-button @click="handleResetForm"> 重置 </a-button>
            <a-button type="primary" @click="handleSearchBtnClick">
              查询
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form>
    <a-tabs v-model:activeKey="currentTabKey" @change="handleResetForm">
      <a-tab-pane v-if="tabSet.has('official')" key="official" tab="官方歌曲">
        <!-- 官方曲库列表 -->
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          :scroll="{ x: 1400 }"
          rowKey="id"
          size="small"
          :pagination="paginations"
        >
          <template #songtype="{ text }">
            <span>{{ SheetSongType[text] }}</span>
          </template>
          <template #tags="{ text }">
            <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
          </template>
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">添加</a>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane v-if="tabSet.has('personal')" key="personal" tab="我的歌曲">
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          :scroll="{ x: 1300 }"
          rowKey="id"
          size="small"
          :pagination="paginations"
        >
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <select-book-modal
      ref="bookModalRef"
      :type="currentTabKey === 'official' ? 1 : 2"
      v-model:visible="bookModalVisible"
      @ok="handleBookSelectOk"
    />
  </a-modal>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue'
import { getInfoPermissionFromUser } from './commonUtil'
import * as service from '../service'
import { SheetSongType, SheetTypeOptions } from './config'
import { debounce } from '@/utils/common'
import { getOfficialTag } from '@/services/songs'
import { getTeacherTags } from '@/services/common'
import { SearchOutlined } from '@ant-design/icons-vue'
import SelectBookModal from '@/views/songs/components/SelectBookModal.vue'
export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  components: {
    SearchOutlined,
    SelectBookModal
  },
  setup (props, { emit }) {
    // 弹窗可见性
    const visibleValue = computed(() => props.visible)
    // 关闭事件
    const handleCancel = () => {
      emit('onCancel')
    }

    // tab列的key值
    const tabKey = getInfoPermissionFromUser()
    // computed属性的tabkey值的集合
    const tabSet = computed(() => new Set(tabKey))

    const currentTabKey = ref(tabKey[0] || null)

    const formData = ref({
      name: undefined,
      song_code: undefined,
      type: undefined,
      tag: [],
      book_id: undefined
    })

    const bookModalVisible = ref(false)
    const bookModalRef = ref()
    const bookShowName = ref('教材名称-章节目录')

    const handleBookSelectOk = (value) => {
      bookShowName.value = value.labels.join('-')
      formData.value.book_id = value.values[0]
      formData.value.book_section_id = value.values[1]
      bookModalVisible.value = false
      currentPage = 1
    }

    const handleShowBookModal = () => {
      bookModalVisible.value = true
    }

    const tagOpts = ref([])

    const getTagSearch = debounce(async (value) => {
      let SearchFunc = null
      if (currentTabKey.value === 'official') {
        SearchFunc = getOfficialTag
      } else if (currentTabKey.value === 'personal') {
        SearchFunc = getTeacherTags
      }
      if (SearchFunc !== null) {
        const tagList = await SearchFunc({ keyword: value, current: 1, per_page: 20 })
        tagOpts.value = tagList.items.map(item => { return { label: item.tag, value: item.tag } })
      }
    })

    // 列表数据
    const dataList = ref([])
    const cloumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '歌曲编码',
        dataIndex: 'song_code',
        key: 'song_code',
        width: 80,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        key: 'py',
        ellipsis: true,
        width: 50
      },
      {
        title: '歌曲类型',
        dataIndex: 'song_type',
        key: 'song_type',
        slots: { customRender: 'songtype' },
        width: 50
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        width: 100,
        ellipsis: true
      },
      {
        title: '关联教材',
        dataIndex: 'book.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材章节',
        dataIndex: 'section.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]
    let currentPage = 1
    const handlePageChange = (page) => {
      currentPage = page
      handleSearch()
    }

    const paginations = ref({ current: currentPage, pageSize: 10, onChange: handlePageChange })
    const loading = ref(false)

    const queryOfficialList = async (params) => {
      try {
        loading.value = true
        const data = await service.getOfficialSheetSong({
          ...params,
          per_page: paginations.value.pageSize,
          page: currentPage,
          except_song_types: [4],
          except_statuses: [2]
        })
        paginations.value = { ...paginations.value, ...data.meta, pageSize: +data.meta.per_page, current: data.meta.current_page }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const queryTeacherList = async (params) => {
      try {
        loading.value = true
        const data = await service.getTeacherSheetSong({
          ...params,
          except_statuses: 2,
          per_page: paginations.value.pageSize,
          page: currentPage,
          except_song_types: [4]
        })
        paginations.value = { ...paginations.value, ...data.meta, pageSize: +data.meta.per_page, current: data.meta.current_page }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const handleSearch = async () => {
      let searchFunc = null
      if (currentTabKey.value === 'official') {
        searchFunc = queryOfficialList
      } else if (currentTabKey.value === 'personal') {
        searchFunc = queryTeacherList
      }

      if (searchFunc !== null) {
        const params = formData.value
        await searchFunc(params)
      }
    }

    const handleSearchBtnClick = async () => {
      currentPage = 1
      await handleSearch()
    }

    const handleResetForm = () => {
      formData.value = {
        name: undefined,
        song_code: undefined,
        type: undefined,
        tag: [],
        book_id: undefined,
        book_section_id: undefined
      }
      getTagSearch('')
      currentPage = 1
      bookShowName.value = '教材名称-章节目录'
      bookModalRef.value.handleReset()
      handleSearch()
    }

    onBeforeMount(() => {
      getTagSearch('')
      handleSearch()
    })

    const handleAddSong = (record) => {
      emit('onOk', { sid: record.id, source: currentTabKey.value })
      emit('onCancel')
    }

    return {
      visibleValue,
      currentTabKey,
      tabSet,
      dataList,
      cloumns,
      paginations,
      loading,
      SheetSongType,
      SheetTypeOptions,
      formData,
      tagOpts,
      getTagSearch,
      bookShowName,
      bookModalRef,
      bookModalVisible,
      handleSearchBtnClick,
      handleBookSelectOk,
      handleShowBookModal,
      handleSearch,
      handleResetForm,
      handleCancel,
      queryOfficialList,
      handleAddSong
    }
  }

}
</script>

<style>
.modal_style {
  min-height: 500px;
}
</style>
