<template>
  <div style="margin: 16px">
    <a-alert type="info"  show-icon >
      <template #message>
        <div>
          <span style="margin-left: 24px">已选中 <a href="#">{{total}}</a> 项</span>
          <a style="float: right" @click="$emit('clearSelected')">清空选中</a>
        </div>
      </template>
    </a-alert>
  </div>
</template>

<script>
import { toRefs } from 'vue'
export default {
  name: 'TableSelectInfo',
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  emits: ['clearSelected'],
  setup (props) {
    return {
      ...toRefs(props)
    }
  }
}
</script>

<style scoped>

</style>
