import { postRequest, getRequest, deleteRequest } from '@/utils/http'

export async function getOfficialSheetSongs (params) {
  return getRequest('/official/sheet-songs', params)
}

export async function createOfficialSheetSong (params) {
  return postRequest('/official/sheet-song/create', params)
}

export async function editOfficialSheetSong (id, params) {
  return postRequest(`/official/sheet-song/${id}/edit`, params)
}

export async function deleteOfficialSheetSong (id) {
  return deleteRequest(`/official/sheet-song/${id}/delete`)
}

export async function deleteOfficialEnjoySong (id) {
  return deleteRequest(`/official/enjoy-song/${id}/delete`)
}

export async function getOfficialSheetSongInfo (id) {
  return getRequest(`/official/sheet-song/${id}`)
}

export async function getOfficialEnjoySongs (params) {
  return getRequest('/official/enjoy-songs', params)
}

export async function getOfficialEnjoySongInfo (id) {
  return getRequest(`/official/enjoy-song/${id}`)
}

export async function createOfficialEnjoySong (params) {
  return postRequest('/official/enjoy-song/create', params)
}

export async function editOfficialEnjoySong (id, params) {
  return postRequest(`/official/enjoy-song/${id}/edit`, params)
}
//
export async function getOfficialMusicRhythms (params) {
  return getRequest('/official/music-rhythms', params)
}

export async function getOfficialMusicRhythmInfo (id) {
  return getRequest(`/official/music-rhythms/${id}`)
}

export async function getOfficialTag (params) {
  return getRequest('/official/tags', params)
}

export async function setOfficialEnjoySongTags (id, params) {
  return postRequest(`/official/enjoy-song/${id}/set-tags`, params)
}

export async function setOfficialSheetSongTags (id, params) {
  return postRequest(`/official/sheet-song/${id}/set-tags`, params)
}

export async function checkSheetSongCode (params) {
  return getRequest('/official/sheet-song/check-songcode-exist', params)
}

export async function checkEnjoySongCode (params) {
  return getRequest('/official/enjoy-song/check-songcode-exist', params)
}

export async function getPersonalSheetSongs (params) {
  return getRequest('/teacher/sheet-songs', params)
}

export async function createPersonalSheetSong (params) {
  return postRequest('/teacher/sheet-song/create', params)
}

export async function deletePersonalSheetSong (id) {
  return deleteRequest(`/teacher/sheet-song/${id}/delete`)
}

export async function setPersonalSheetSongTags (id, params) {
  return postRequest(`/teacher/sheet-song/${id}/set-tags`, params)
}

export async function getPersonalSheetSongInfo (id) {
  return getRequest(`/teacher/sheet-song/${id}`)
}

export async function editPersonalSheetSong (id, params) {
  return postRequest(`/teacher/sheet-song/${id}/edit`, params)
}

export async function getPersonalTag (params) {
  return getRequest('/teacher/tags', params)
}

export async function getPersonalEnjoySongs (params) {
  return getRequest('/teacher/enjoy-songs', params)
}

export async function deletePersonalEnjoySong (id) {
  return deleteRequest(`/teacher/enjoy-song/${id}/delete`)
}

export async function getPersonalEnjoySongInfo (id) {
  return getRequest(`/teacher/enjoy-song/${id}`)
}

export async function createPersonalEnjoySong (params) {
  return postRequest('/teacher/enjoy-song/create', params)
}

export async function setPersonalEnjoySongTags (id, params) {
  return postRequest(`/teacher/enjoy-song/${id}/set-tags`, params)
}

export async function editPersonalEnjoySong (id, params) {
  return postRequest(`/teacher/enjoy-song/${id}/edit`, params)
}

export const copySheetSong = async (id) => {
  return postRequest('/teacher/sheet-song/copy-from-official', { official_sheet_song_id: id })
}

export const copyEnjoySong = async (id) => {
  return postRequest('/teacher/enjoy-song/copy-from-official', { official_enjoy_song_id: id })
}

// 欣赏歌曲版本相关
export const getEnjoySongVersions = async (params) => {
  return getRequest('/official/enjoy-song-versions', params)
}

export const deleteEnjoySongVersion = async (id) => {
  return deleteRequest(`/official/enjoy-song-version/${id}/delete`)
}

export async function setEnjoyVersionTest (id) {
  return postRequest(`/official/enjoy-song-version/${id}/set-test`)
}
export async function setEnjoyVersionProduction (id) {
  return postRequest(`/official/enjoy-song-version/${id}/set-production`)
}
export async function setEnjoyVersionDevelop (id) {
  return postRequest(`/official/enjoy-song-version/${id}/set-develop`)
}
export async function batchSetEnjoySongTest (params) {
  return postRequest('/official/enjoy-song/batch-set-test', params)
}
export async function batchSetEnjoySongProduction (params) {
  return postRequest('/official/enjoy-song/batch-set-production', params)
}
export async function getOfficialEnjoySongVersionInfo (id) {
  return getRequest(`/official/enjoy-song-version/${id}`)
}
// 谱例歌曲版本相关
export const getSheetSongVersions = async (params) => {
  return getRequest('/official/sheet-song-versions', params)
}

export const deleteSheetSongVersion = async (id) => {
  return deleteRequest(`/official/sheet-song-version/${id}/delete`)
}

export async function setSheetVersionTest (id) {
  return postRequest(`/official/sheet-song-version/${id}/set-test`)
}
export async function setSheetVersionProduction (id) {
  return postRequest(`/official/sheet-song-version/${id}/set-production`)
}
export async function setSheetVersionDevelop (id) {
  return postRequest(`/official/sheet-song-version/${id}/set-develop`)
}
export async function batchSetSheetSongTest (params) {
  return postRequest('/official/sheet-song/batch-set-test', params)
}
export async function batchSetSheetSongProduction (params) {
  return postRequest('/official/sheet-song/batch-set-production', params)
}
export async function getOfficialSheetSongVersionInfo (id) {
  return getRequest(`/official/sheet-song-version/${id}`)
}
export async function setSheetSongStatus (id, params) {
  return postRequest(`/official/sheet-song/${id}/set-status`, params)
}
export async function setEnjoySongStatus (id, params) {
  return postRequest(`/official/enjoy-song/${id}/set-status`, params)
}
