<template>
  <div class="loadingDiv">
    <a-spin size="large" />
  </div>
</template>

<script>
export default {
  name: 'CommonLoading'
}
</script>

<style scoped>
.loadingDiv{
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-top: 50px;
  text-align: center;
  background-color: #d3d3d3;
  opacity: .6;
  z-index: 8;
}
</style>
