<template>
  <div ref="modal">
    <a-modal :getContainer="() => modal" :footer="null" width="550px" :visible="visibleValue" @cancel="handleCancel">
      <div class="searchDiv">
        <search-bar v-model:value="relateState.name" :on-search="getList" :on-change="getList"/>
      </div>

      <div class="radioDiv">
        <div class="radioDivRow">
          <div style="display: inline-block">学段：</div>
          <div style="display: inline-block">
            <common-radio v-model:value="relateState.gradePeriod" :options="formOpts.gradePeriod" />
          </div>
        </div>
        <div class="radioDivRow">
          <div style="display: inline-block">学科：</div>
          <div style="display: inline-block">
            <CommonRadio v-model:value="relateState.subject" :options="formOpts.subject" />
          </div>
        </div>
        <div class="radioDivRow">
          <div style="display: inline-block">版本：</div>
          <div style="display: inline-block;width: 430px">
            <CommonRadio v-model:value="relateState.pressVersion" :options="formOpts.pressVersion" />
          </div>
        </div>
      </div>

      <div class="tableDiv">
        <table-select-info
          v-if="hasSelected"
          :total="tableState.selectedCounts || 0"
          @clearSelected="handleClearSelected"
        />
        <a-table
          :row-selection="{
            selectedRowKeys: tableState.selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: handleGetCheckBoxProps,
          }"
          :dataSource="tableState.data"
          :columns="tableState.columns"
          rowKey="id"
          :pagination="tableState.pagination"
          :loading="tableState.loading"
          @change="handleTableChange"
        />
        <a-button type="primary" class="submitBtn" @click="handleSubmit">
          确定
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { defineEmits, reactive, onBeforeMount, defineProps, computed, watch, ref } from 'vue'
import SearchBar from '@/components/common/SearchBar'
import { useRoute } from 'vue-router'
import { debounce } from '@/utils/common'
import TableSelectInfo from '@/components/common/TableSelectInfo'
import {
  editCourseware,
  getCoursewareInfo,
  getRelateTeachPlanCoursewaresList,
  getRelateSpeakLessonCoursewaresList,
  getRelatePPTCoursewaresList,
  getCoursewareVersionInfo
} from '@/services/courseware'
import {
  getGradePeriodEnum,
  getPressVersionEnum,
  getSubjectEnum
} from '@/services/teaching-material'
import CommonRadio from '@/components/common/CommonRadio'

const modal = ref()

const route = useRoute()

const emit = defineEmits(['ok', 'onCancel', 'onClear'])

const props = defineProps({
  relateCoursewareIds: Array,
  relateCoursewares: Array,
  coursewareId: {
    type: Number,
    default: 0
  },
  clearKeys: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  relateType: {
    type: String,
    default: () => null
  },
  visible: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  coursewareType: {
    type: String,
    default: () => {
      return 'official'
    }
  }
})

const pageState = reactive({
  type: route.params.type,
  relateType: props.relateType,
  status_code: -1,
  id: props.coursewareId === 0 ? parseInt(route.params.id) : props.coursewareId,
  current: {
    relate_courseware_ids: []
  }
})

const formOpts = reactive({
  subject: [],
  gradePeriod: [],
  pressVersion: []
})

const relateState = reactive({
  name: '',
  isRelate: true,
  coursewareType: props.coursewareType,
  collected_ids: [],
  subject: 0,
  gradePeriod: 0,
  pressVersion: 0
})

const tableState = reactive({
  selectedCounts: [],
  selectedRowKeys: [],
  collectedInfo: [],
  data: [],
  columns: [],
  loading: true,
  addOptions: true,
  pagination: {
    size: 'small',
    current: 1,
    total: 1,
    pageSize: 10,
    showTotal: total => `共${total}条`,
    showLessItems: true
  }
})

tableState.columns = [
  {
    title: '课件名称',
    dataIndex: 'name',
    ellipsis: true
  }
]

// 将被事件调用的方法
const autoClear = () => {
  tableState.selectedRowKeys = []
  tableState.selectedCounts = 0
  tableState.collectedInfo = {}
}
const filterPlanOrSpeak = (type, info) => {
  if (type === 'lesson') {
    const relate_plan = info.filter(item => {
      return item.type === 'plan'
    })
    tableState.selectedCounts = relate_plan.length
  } else if (type === 'speak') {
    const relate_speak = info.filter(item => {
      return item.type === 'speak'
    })
    tableState.selectedCounts = relate_speak.length
  }
}
const getTableInfo = (info) => {
  const type = info[0]
  if (!type) {
    autoClear()
    emit('onClear', relateState.isRelate)
    return
  }
  if (type.constructor === Number) {
    tableState.selectedRowKeys = info
  } else if (type.constructor === Object) {
    tableState.selectedRowKeys = info.map(i => i.id)
  }
  tableState.selectedCounts = tableState.selectedRowKeys.length
}
const getRelateData = (items, pagination) => {
  tableState.data = items
  tableState.loading = false
  tableState.pagination = { ...tableState.pagination, ...pagination }
}

watch(() => props.relateCoursewares, (val) => {
  if (props.relateCoursewares) {
    if (Object.keys(props.relateCoursewares).length !== 0) {
      pageState.current.relate_courseware_ids = props.relateCoursewares.map(i => i.id)
      getTableInfo(val)
      tableState.collectedInfo = val
    } else if (Object.keys(props.relateCoursewares).length === 0) {
      autoClear()
    }
  }
}, {
  immediate: true,
  deep: true
})
watch(() => props.relateCoursewareIds, (val) => {
  pageState.current.relate_courseware_ids = props.relateCoursewareIds
  if (val && Object.keys(val).length !== 0) {
    tableState.selectedRowKeys = val
    tableState.selectedCounts = tableState.selectedRowKeys.length
  }
  sessionStorage.setItem('collectedInfo', JSON.stringify({}))
}, {
  immediate: true,
  deep: true
})
watch(() => props.relateType, (val) => {
  const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))
  if (relate_coursewares && Object.keys(relate_coursewares).length !== 0) {
    filterPlanOrSpeak(val, relate_coursewares)
  }
}, {
  immediate: true,
  deep: true
})
watch(() => relateState.subject, () => {
  getList()
})
watch(() => relateState.gradePeriod, () => {
  getList()
})
watch(() => relateState.pressVersion, () => {
  getList()
})

const visibleValue = computed(() => props.visible)
const hasSelected = computed(() => tableState.selectedCounts > 0)

const handleCancel = () => {
  if (tableState.selectedCounts !== 0) {
    if (!tableState.addOptions) { // 取消勾选后关闭弹窗
      tableState.selectedRowKeys = pageState.current.relate_courseware_ids
      tableState.selectedCounts = tableState.selectedRowKeys.length
    } else if (tableState.addOptions) { // 添加勾选后关闭弹窗
      if (pageState.current.relate_courseware_ids) {
        tableState.selectedRowKeys = pageState.current.relate_courseware_ids
        tableState.selectedCounts = tableState.selectedRowKeys.length
        if (Object.keys(tableState.collectedInfo).length !== 0) {
          tableState.collectedInfo = tableState.collectedInfo.filter(item => {
            return pageState.current.relate_courseware_ids.indexOf(item.id) !== -1
          })
        }
      }
    } else {
      autoClear()
    }
  }
  emit('onCancel')
}

const handleClearSelected = () => {
  const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))
  let leftArr
  if (pageState.relateType === 'lesson') {
    if (relate_coursewares) {
      leftArr = relate_coursewares.filter(item => { return item.type === 'speak' })
    }
    tableState.collectedInfo = leftArr
    tableState.selectedRowKeys = leftArr.map(i => i.id)
    tableState.selectedCounts = 0
  } else if (pageState.relateType === 'speak') {
    if (relate_coursewares) {
      leftArr = relate_coursewares.filter(item => { return item.type === 'plan' })
    }
    tableState.collectedInfo = leftArr
    tableState.selectedRowKeys = leftArr.map(i => i.id)
    tableState.selectedCounts = 0
  } else {
    autoClear()
  }
  emit('onClear', relateState.isRelate)
}

const onSelectChange = selectedRowKeys => {
  // 存入会话存储的collectedInfo
  let collectedInfo = JSON.parse(sessionStorage.getItem('collectedInfo'))
  const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))

  // 一、当前勾选数量大于上一次勾选数量（添加）
  if (tableState.selectedRowKeys.length < selectedRowKeys.length) {
    tableState.addOptions = true
    getTableInfo(selectedRowKeys)
    const selectedInfo = tableState.data.filter(items => { // 获取关联课件的数据
      return tableState.selectedRowKeys.indexOf(items.id) !== -1
    })

    // 判断课件目前是否已有关联的课件
    if (Object.keys(tableState.collectedInfo).length !== 0) { // 如果已经关联
      collectedInfo = tableState.collectedInfo.concat(selectedInfo)
      tableState.collectedInfo = collectedInfo.filter((element, index, self) => {
        return self.findIndex(el => el.id === element.id) === index
      })
    } else if (Object.keys(tableState.collectedInfo).length === 0) { // 新建课件第一次关联第一次勾选
      tableState.collectedInfo = selectedInfo
    }

    // 二、当前勾选数量小于上一次勾选数量（取消）
  } else if (tableState.selectedRowKeys.length > selectedRowKeys.length) {
    tableState.addOptions = false
    getTableInfo(selectedRowKeys)
    if (Object.keys(collectedInfo).length === 0) { // 编辑教案/说课一开始就取消关联的情况
      collectedInfo = relate_coursewares
    }
    tableState.collectedInfo = collectedInfo.filter(item => {
      return selectedRowKeys.indexOf(item.id) !== -1
    })
  }

  // 三、判断tableSelectInfo组件显示:若为新建/编辑PPT的情况需要筛选说课/教案勾选数量
  if (pageState.relateType && relate_coursewares) {
    let leftArr
    if (pageState.relateType === 'lesson') { // 点击添加教案时:除去勾选的说课信息
      const selectSpeakArr = relate_coursewares.filter(item => { return item.type === 'speak' })
      const selectedIds = selectSpeakArr.map(i => i.id)
      leftArr = selectedRowKeys.filter(item => {
        return selectedIds.indexOf(item) === -1
      })
    } else if (pageState.relateType === 'speak') { // 点击添加说课时:除去勾选的教案信息
      const selectedPlanArr = relate_coursewares.filter(item => { return item.type === 'plan' })
      const selectedIds = selectedPlanArr.map(i => i.id)
      leftArr = selectedRowKeys.filter(item => {
        return selectedIds.indexOf(item) === -1
      })
    }
    tableState.selectedCounts = leftArr.length
  } else { // 不用筛选的情况(新建/编辑说课或教案时)
    tableState.selectedCounts = tableState.selectedRowKeys.length
  }
}

const handleTableChange = pag => {
  tableState.pagination = pag
  getList({ page: tableState.pagination.current })
}

const handleGetCheckBoxProps = (record) => ({
  name: record.name
})

const handleSubmit = async () => {
  if (Object.keys(tableState.selectedRowKeys).length === 0) { // 没有勾选课件的情况
    const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))
    pageState.current.relate_courseware_ids = []
    if (props.coursewareId) { // 编辑ppt时
      await editCourseware(props.coursewareId, { relate_courseware_ids: [] })
      pageState.status_code = 0
      emit('onOk', { coursewareSource: 'official', status_code: pageState.status_code, coursewareId: props.coursewareId, relate_coursewares: [] })
      if (relate_coursewares) {
        sessionStorage.clear()
        message.success('关联成功')
      }
    } else if (!props.coursewareId) {
      autoClear()
      if (relate_coursewares) {
        sessionStorage.clear()
        pageState.status_code = 0
        emit('onOk', { coursewareSource: 'official', status_code: pageState.status_code, coursewareId: props.coursewareId, relate_coursewares: [] })
      }
    }
    emit('onCancel')
    return
  }
  pageState.current.relate_courseware_ids = tableState.selectedRowKeys
  if (Object.keys(tableState.collectedInfo).length === 0) {
    handleCancel()
    return
  }
  // 编辑/新建教案或说课的情况
  if (pageState.type === 'plan' || pageState.type === 'speak') {
    let relate_ppts = JSON.parse(localStorage.getItem('relate_ppts'))
    relate_ppts = tableState.collectedInfo
    localStorage.setItem('relate_ppts', JSON.stringify(relate_ppts))
    emit('ok', relateState.isRelate)
  } else {
    // 编辑PPT的情况
    if (props.coursewareId) {
      await editCourseware(props.coursewareId, { relate_courseware_ids: pageState.current.relate_courseware_ids })
      pageState.status_code = 0
      message.success('关联成功')
      emit('onOk', { coursewareSource: 'official', status_code: pageState.status_code, coursewareId: props.coursewareId, relate_coursewares: tableState.collectedInfo })
    }
    // 新建PPT/编辑接口请求成功后的情况
    if (pageState.relateType === 'lesson') {
      const relate_lessons = tableState.collectedInfo.filter(i => { return i.type === 'plan' })
      sessionStorage.setItem('relate_lessons', JSON.stringify(relate_lessons))
    } else if (pageState.relateType === 'speak') {
      const relate_speaks = tableState.collectedInfo.filter(i => { return i.type === 'speak' })
      sessionStorage.setItem('relate_speaks', JSON.stringify(relate_speaks))
    }
    // 整理relate_courseware避免ppt切换tab时qiankun事件遍历的relate_courseware存在null
    const relate_lessons = JSON.parse(sessionStorage.getItem('relate_lessons'))
    const relate_speaks = JSON.parse(sessionStorage.getItem('relate_speaks'))
    let relate_coursewares
    if (!relate_lessons) {
      relate_coursewares = relate_speaks
    } else if (!relate_speaks) {
      relate_coursewares = relate_lessons
    } else if (relate_lessons && relate_speaks) {
      relate_coursewares = relate_lessons.concat(relate_speaks)
    }
    relate_coursewares = relate_coursewares.filter((element, index, self) => {
      return self.findIndex(el => el.id === element.id) === index
    })
    sessionStorage.setItem('relate_coursewares', JSON.stringify(relate_coursewares))
    // 回调数据给ppt-editor
    if (!props.coursewareId) {
      emit('onOk', { relate_coursewares: relate_coursewares, coursewareSource: 'official', status_code: pageState.status_code, coursewareId: props.coursewareId })
    }
  }
  sessionStorage.setItem('collectedInfo', JSON.stringify(tableState.collectedInfo))
  emit('onCancel')
}

const getList = debounce(async (params) => {
  tableState.loading = true
  params = { name: relateState.name, page: 1, ...params }
  if (relateState.subject) {
    params.subject_cat_id = relateState.subject
  }
  if (relateState.gradePeriod > 0) {
    params.grade_period_cat_id = relateState.gradePeriod
  }
  if (relateState.pressVersion > 0) {
    params.press_version_cat_id = relateState.pressVersion
  }
  const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))
  if (relate_coursewares) {
    relateState.collected_ids = relate_coursewares?.map(i => i.id)
  }
  if (pageState.type === 'plan' || pageState.type === 'speak') { // 当前页面为编辑/新建教案/说课页面
    const { items, pagination } = await getRelatePPTCoursewaresList(0, params)
    getRelateData(items, pagination)
  }
  if (!pageState.relateType) {
    return
  }
  // 当前页面为编辑/新建ppt页面
  if (pageState.relateType === 'lesson') { // 当前需要关联的课件为教案时
    const { items, pagination } = await getRelateTeachPlanCoursewaresList(0, params)
    getRelateData(items, pagination)
  } else if (pageState.relateType === 'speak') { // 当前需要关联的课件为说课时
    const { items, pagination } = await getRelateSpeakLessonCoursewaresList(0, params)
    getRelateData(items, pagination)
  }
})

const getInfo = async () => {
  if (pageState.action === 'version-detail') {
    pageState.id = (await getCoursewareVersionInfo(pageState.id)).courseware_id
  }
  if (props.coursewareId) {
    pageState.id = props.coursewareId
    pageState.current = await getCoursewareInfo(pageState.id)
    pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(item => item.id)
    if (Object.keys(pageState.current.relate_coursewares).length !== 0) {
      getTableInfo(pageState.current.relate_coursewares)
      filterPlanOrSpeak(props.relateType, pageState.current.relate_coursewares)
    }
    sessionStorage.setItem('relate_coursewares', JSON.stringify(pageState.current.relate_coursewares))
  }
}

const getOptions = () => {
  getSubjectEnum().then((data) => {
    formOpts.subject = data.map(i => {
      i.label = i.name
      i.value = i.id
      return i
    })
  })
  getPressVersionEnum().then((data) => {
    formOpts.pressVersion = data.map(i => {
      i.label = i.name
      i.value = i.id
      return i
    })
  })
  getGradePeriodEnum().then((data) => {
    formOpts.gradePeriod = data.map(i => {
      i.label = i.name
      i.value = i.id
      return i
    })
  })
}

onBeforeMount(async () => {
  getOptions()
  await getInfo()
  await getList()
  const relate_coursewares = JSON.parse(sessionStorage.getItem('relate_coursewares'))
  if (props.clearKeys) {
    if (props.coursewareId && Object.keys(relate_coursewares).length === 0) {
      autoClear()
    } else if (!relate_coursewares) {
      autoClear()
    }
  } else if (props.coursewareId && Object.keys(relate_coursewares).length === 0) {
    autoClear()
  }
})
</script>

<style scoped lang="less">
.searchDiv /deep/ .searchBar{
  padding: 0;
  width: 92%;
  height: 100%;
}
.searchDiv /deep/ .searchInput {
  width: 100%;
  height: 45px;
}
.searchDiv /deep/ .searchBtnDiv {
  display: none;
}
.searchDiv /deep/ .anticon-search {
  font-size: 25px;
}
.searchDiv /deep/ .ant-input {
  font-size: 16px;
}
.radioDiv {
  padding: 12px;
  .radioDivRow {
    display: flex;
    margin-bottom: 12px;
  }
}

.tableDiv {
  min-height: 40vh;
  padding: 5px 10px 0 10px;
  transform: translate(0,0);
  .submitBtn{
    position: fixed;
    width: 88px;
    height: 36px;
    bottom: 5px;
    right: 10px;
    border-radius: 4px;
  }
}

/deep/ .ant-table-pagination {
  display: inline-block;
  float: none;
  margin: 20px 80px 10px 70px;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px;
}
//调整head行属性
/deep/ .ant-table-thead > tr > th {
  padding: 10px;
}
</style>
