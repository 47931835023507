<template>
  <div>
    <div v-for="(item, index) in dataList" :key="`${item.id}name:${item.name}`">
      <!-- 渲染章节节点 -->
      <div v-if="item.type === TypeMap.Chapter">
        <chapter
          :allList="allListValue"
          :item="dataList[index]"
          v-model:selectKeys="selectKeysValue"
          :currentSelect="currentSelectValue"
          :canEdit="canEditValue"
          :moveUp="index !== 0"
          :moveDown="index !== dataList.length - 1"
          @onDelete="handleDelete"
          @onEdit="handleIntoEdit"
          @showMask="handleShowMask"
          @showChildren="handleShowChildren"
          @hidenChildren="handleHideChildren"
          @updateCurrentSelect="handleUpdateCurrentSelect"
          @onCreate="() => handleChildNodeAdd(dataList[index])"
          @moveChildItem="handleOnMoveItem"
          @onContractCourseware="handleContactCourseware"
          @onShowMoreCoursewares="handleMoreCoursewares"
        ></chapter>
        <div v-if="selectKeysValue.indexOf(item.id) > -1" class="child_list">
          <trees
            v-model:selectKeys="selectKeysValue"
            v-model:currentSelect="currentSelectValue"
            :allList="allListValue"
            :list="dataList[index].children"
            :isRoot="false"
            :canEdit="canEditValue"
            @update="handleUpdate"
            @openPpt="handleOpenPPT"
            @showMask="handleShowMask"
            @beforeSelect="handleBeforeSelect"
            @showLoading="handleShowLoading"
            @onMoveItem="handleOnMoveItem"
            @onContact="handleContactCourseware"
          ></trees>
        </div>
        <div
          v-if="selectKeysValue.indexOf(item.id) > -1"
          class="children_add_option"
        >
          <a-button
            @click="() => handleChildNodeAdd(dataList[index])"
            size="small"
          >
            <strong>+ </strong>添加章节/课时
          </a-button>
        </div>
      </div>
      <div v-if="item.type === TypeMap.Periods">
        <periods
          :allList="allListValue"
          :value="item"
          v-model:selectKeys="selectKeysValue"
          :currentSelect="currentSelectValue"
          :canEdit="canEditValue"
          :moveUp="index !== 0"
          :moveDown="index !== dataList.length - 1"
          @onDelete="handleDelete"
          @onEdit="handleIntoEdit"
          @openPpt="handleOpenPPT"
          @showMask="handleShowMask"
          @beforeSelect="handleBeforeSelect"
          @updateCurrentSelect="handleUpdateCurrentSelect"
          @moveChildItem="handleOnMoveItem"
          @onContractCourseware="handleContactCourseware"
        ></periods>
      </div>
    </div>
    <div v-if="isRootValue && canEditValue" class="root_opt_body">
      <a-button class="root_add_option" @click="handleRootNodeAdd" size="small">
        <strong>+ </strong>添加章节/课时
      </a-button>
    </div>
    <tree-node-modal
      v-if="visible"
      :visible="visible"
      :item="currentNode"
      :actionType="actionType"
      :allList="allListValue"
      :loading="submitLoading"
      @onChange="handleListChange"
      @onCancel="handleModalCancel"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import { modalActionType, TreeItemType } from './config'
import Chapter from './Chapter.vue'
import Periods from './Periods.vue'
import TreeNodeModal from './TreeNodeModal.vue'
import { message } from 'ant-design-vue'
import { filterParams, queryChildByIndex } from './commonUtil'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    Chapter,
    Periods,
    TreeNodeModal
  },
  emits: ['update', 'reload', 'openPpt', 'beforeSelect', 'showMask', 'update:selectKeyss', 'update:currentSelect', 'showLoading', 'onContact'],
  name: 'trees',
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    selectKeys: {
      type: Array,
      default: () => { return [] }
    },
    currentSelect: {
      type: Object,
      default: () => { return { id: -1 } }
    },
    isRoot: {
      type: Boolean,
      default: () => { return true }
    },
    reload: {
      type: Function
    },
    update: {
      type: Function
    },
    allList: {
      type: Array,
      default: () => {
        return []
      }
    },
    canEdit: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, { emit }) {
    // 当前展开的节点合集
    const selectKeysValue = reactive(props.selectKeys)
    // 这一层节点的数据
    const dataList = computed(() => props.list)
    // 所有的树状信息数据
    const allListValue = computed(() => props.allList)
    // 当前选中的节点信息
    const currentSelectValue = reactive(props.currentSelect)
    // 弹窗是否可见
    const visible = ref(false)
    // 当前弹窗编辑的节点
    const currentNode = ref({})
    // 弹窗表单的操作类型
    const actionType = ref(modalActionType.Create)
    // 是否为最顶上那一层
    const isRootValue = ref(props.isRoot)
    // 是否在提交表单
    const submitLoading = ref(false)
    // 是否可以编辑
    const canEditValue = ref(props.canEdit)
    const store = useStore()

    // 更新信息
    const handleUpdate = (info, callback = undefined) => {
      emit('update', info, callback)
    }

    // 隐藏子节点
    const handleHideChildren = (item) => {
      // 将节点收起时，去掉选择列表里这个节点
      const index = selectKeysValue.indexOf(item.id)
      selectKeysValue.splice(index, 1)
      // 如果当前收起的节点是选中的节点
      if (currentSelectValue.id === item.id) {
        currentSelectValue.id = -1
        emit('update:currentSelect', currentSelectValue)
      }
      // 通知父组件，由于这里是递归的，所以需要一层层通知上去保持selectKeys一致
      emit('update:selectKeys', selectKeysValue)
    }

    // 显示子节点
    const handleShowChildren = (item) => {
      selectKeysValue.push(item.id)
    }

    // 添加第一个根节点
    const handleRootNodeAdd = () => {
      currentNode.value = {}
      actionType.value = modalActionType.CreateRootNode
      visible.value = true
    }

    // 弹出创建弹窗
    const handleChildNodeAdd = (currentData) => {
      currentNode.value = currentData
      actionType.value = modalActionType.Create
      visible.value = true
    }

    // 关闭弹窗
    const handleModalCancel = () => {
      visible.value = false
    }

    // 通知父应用打开pp
    const handleOpenPPT = (node) => {
      emit('openPpt', node)
    }

    const handleShowLoading = value => {
      emit('showLoading', value)
    }

    // 从最新的树状信息中获取当前选中的节点的节点信息
    const getCurrentSelectNode = (type, parentNode) => {
      // 普通节点创建
      if (type === modalActionType.Create) {
        if (parentNode) {
          const data = queryChildByIndex(allListValue.value, parentNode, parentNode.children?.length || 0)
          return data
        }
      } else if (type === modalActionType.CreateRootNode) {
        // 最顶层根节点的创建
        // 直接取最顶层上的值
        const list = allListValue.value || []
        if (list.length < 1) {
          return undefined
        }
        const data = list[list.length - 1]
        return data
      }
      return undefined
    }

    /**
     * 更新树状信息
     * 当用户增加或编辑过节点后触发，分为三种类型： 创建根层次的节点， 创建普通的节点和编辑节点
     * @param newTreeList 新的树状信息数据
     * @param node 增加的节点
     * @param type 操作类型
     * @param parentNode 新增节点的父节点信息，如果是创建的操作，其应设置为非undefined值，根据这个父节点获取从接口得到的最新的节点，并将选中状态， 打开的ppt的信息更新
     */
    const handleListChange = (newTreeList, node, type = modalActionType.Create, parentNode = undefined) => {
      const treeInfo = []
      // 构造新的树状信息，并过滤掉无用的参数 ，比如新增节点的时候本地维护的负数id
      filterParams(newTreeList, treeInfo)
      try {
        submitLoading.value = true
        // 请求接口更新数据
        handleUpdate({ sections: treeInfo }, () => {
          submitLoading.value = false
          // 关闭弹窗
          handleModalCancel()
          // 判断当前的操作类型
          if (type === modalActionType.Create) {
            message.success(`新增${node.type === TreeItemType.Chapter ? '章节' : '课时'}成功`)
          } else {
            message.success(`编辑${node.type === TreeItemType.Chapter ? '章节' : '课时'}成功`)
          }

          // 如果是编辑状态
          if (type === modalActionType.Edit) {
            // 编辑的节点是当前打开的ppt节点
            if (node.id === store.state.currentPeriods?.id) {
              store.commit('setCurrentPeriods', node)
            }
          }

          // 当不为编辑的时候
          if (type !== modalActionType.Edit) {
            // 根据增加节点的父节点，获取到从接口得到的最新树状信息的新增节点值
            const newNode = getCurrentSelectNode(type, parentNode)
            // 如果为章节节点，当前选中高亮的节点改为这个节点
            if (newNode.type === TreeItemType.Chapter) {
              currentSelectValue.id = newNode.id
              selectKeysValue.push(newNode.id)
              // 通知父组件更新
              emit('update:currentSelect', currentSelectValue)
              emit('update:selectKeyss', selectKeysValue)
            } else if (node.type === TreeItemType.Periods) {
              // 如果新增的节点为章节节点，判断当前打开的PPT保存没有
              handleBeforeSelect(() => {
                // 新增的是课时节点则将默认打开这个ppt
                store.commit('setCurrentPeriods', newNode)
                handleOpenPPT(newNode)
                currentSelectValue.id = newNode.id
                emit('update:currentSelect', currentSelectValue)
              })
            }
          }
        })
      } catch (error) {
        console.error(error)
        submitLoading.value = false
      }
    }

    // 删除节点
    const handleDelete = async (params) => {
      emit('showLoading', true)
      const treeInfo = []
      filterParams(params, treeInfo)
      try {
        await handleUpdate({ sections: treeInfo }, () => {
          message.success('删除成功')
          emit('showLoading', false)
        })
      } catch (error) {
        emit('showLoading', false)
        console.error(error)
      }
    }

    // 打开编辑弹窗
    const handleIntoEdit = (params) => {
      currentNode.value = params
      actionType.value = modalActionType.Edit
      visible.value = true
    }

    // 打开遮挡蒙层
    const handleShowMask = () => {
      emit('showMask')
    }

    // 判断当前ppt是否已经保存过了
    const handleBeforeSelect = (next) => {
      emit('beforeSelect', next)
    }

    // 更新当前选中节点的id
    const handleUpdateCurrentSelect = id => {
      currentSelectValue.id = id
      emit('update:currentSelect', currentSelectValue)
    }

    const handleOnMoveItem = (newList) => {
      emit('onMoveItem', newList)
    }

    const handleContactCourseware = (data) => {
      emit('onContact', data)
    }

    const handleMoreCoursewares = (data) => {
      emit('onMoreCoursewares', data)
    }

    return {
      dataList,
      allListValue,
      selectKeysValue,
      TypeMap: TreeItemType,
      currentSelectValue,
      visible,
      currentNode,
      actionType,
      isRootValue,
      submitLoading,
      canEditValue,
      handleRootNodeAdd,
      handleModalCancel,
      handleHideChildren,
      handleShowChildren,
      handleChildNodeAdd,
      handleListChange,
      handleDelete,
      handleIntoEdit,
      handleOpenPPT,
      handleShowMask,
      handleUpdate,
      handleBeforeSelect,
      handleUpdateCurrentSelect,
      handleShowLoading,
      handleOnMoveItem,
      handleContactCourseware,
      handleMoreCoursewares
    }
  }
})
</script>

<style>
.chapter_icon {
  vertical-align: middle;
  height: 30px;
  font-size: 10px;
  margin-right: 2px;
  margin-top: 5px;
}

.child_list {
  padding-left: 10px;
}

.add_node {
  padding-right: 5px;
}

.root_add_option {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.children_add_option {
  padding-left: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}

.root_opt_body {
  margin-top: 15px;
  margin-left: 3px;
}
</style>
