export const TreeItemType = {
  Chapter: 0,
  Periods: 1
}

export const TypeSelectMap = [
  {
    value: 0,
    label: '章节'
  },
  {
    value: 1,
    label: '课时'
  }
]

export const modalActionType = {
  Create: 'create',
  Edit: 'edit',
  CreateRootNode: 'create-root-node'
}

export const SaveResouceTypeMap = {
  PPT: 0,
  KNOWLAGE: 1
}

export const SaveTypeMap = {
  CREATE: 0,
  EDIT: 1
}

export const SongListType = {
  sheet: 'sheet',
  enjoy: 'enjoy',
  RHYTHM: 'rhythm',
  QUESTIONS: 'questions'
}

export const TeachMType = {
  Official: '0',
  Teaching: '1'
}

export const SheetSongType = {
  0: '演唱歌曲',
  2: '合唱歌曲',
  3: '多声部合唱'
  // 4: '钢琴歌曲'
}

// 兼容钢琴歌曲的专门设置
export const SheetSongTypeForContact = {
  0: '演唱歌曲',
  2: '合唱歌曲',
  3: '多声部合唱',
  4: '钢琴歌曲'
}

export const SheetTypeOptions = [
  {
    value: 0,
    label: '演唱歌曲'
  },
  {
    value: 2,
    label: '合唱歌曲'
  },
  {
    value: 3,
    label: '多声部合唱'
  }
  // {
  //   value: 4,
  //   label: '钢琴歌曲'
  // }
]
export const difficultyType = {
  0: '简单',
  1: '中等',
  2: '困难'
}
export const DifficultyType = [
  {
    label: '全部',
    value: -1
  },
  {
    label: '简单',
    value: 0
  }, {
    label: '中等',
    value: 1
  }, {
    label: '困难',
    value: 2
  }
]

export const questionType = {
  1: '单选题',
  2: '多选题',
  3: '编创题',
  4: '判断题',
  5: '演唱题',
  6: '演奏题',
  7: '视唱题',
  8: '连线题',
  9: '填空题',
  10: '简答题',
  11: '套题',
  12: '拖拽题',
  13: '色环题',
  14: '拼图题'
}
export const QuestionTypeOptions = [
  {
    label: '单选题',
    value: 1
  },
  {
    label: '多选题',
    value: 2
  },
  {
    label: '编创题',
    value: 3
  },
  {
    label: '判断题',
    value: 4
  },
  // {
  //   label: '演唱题',
  //   value: 5
  // },
  // {
  //   label: '演奏题',
  //   value: 6
  // },
  // {
  //   label: '视唱题',
  //   value: 7
  // },
  {
    label: '连线题',
    value: 8
  },
  {
    label: '填空题',
    value: 9
  },
  {
    label: '简答题',
    value: 10
  },
  {
    label: '套题',
    value: 11
  },
  {
    label: '拖拽题',
    value: 12
  },
  // {
  //   label: '色环题',
  //   value: 13
  // },
  {
    label: '拼图题',
    value: 14
  }
]

export const dataPermission = [
  {
    type: 'shenyue',
    permissions: ['official']
  },
  {
    type: 'schoolplatform',
    permissions: ['official', 'teacher']
  }
]

export const tabKeyName = {
  official: '官方',
  teacher: '我的'
}

export const MusicRhythmType = {
  0: '下架',
  1: '上架',
  2: '试点'
}
