<template>
  <div class="searchBar">
    <a-input class="searchInput" v-model:value="searchStr" placeholder="请输入名称" @keydown.enter="handleSearch" @change="handleChange">
      <template #prefix>
        <SearchOutlined :style="{color: store.state.themeColor}" />
      </template>
      <template #suffix>
        <div class="searchBtnDiv">
          <a-button type="primary" class="searchBtn" @click="handleSearch">搜索</a-button>
        </div>
      </template>
    </a-input>
    <div class="suffix">
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SearchBar',
  components: {
    SearchOutlined
  },
  emits: ['update:value'],
  props: {
    value: String,
    onSearch: Function,
    onChange: {
      type: Function,
      default: () => {
        return null
      }
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const searchStr = computed({
      get: () => props.value,
      set: val => {
        emit('update:value', val)
      }
    })
    const handleSearch = () => {
      props.onSearch()
    }
    const handleChange = () => {
      props.onChange()
    }
    return {
      store,
      searchStr,
      handleSearch,
      handleChange
    }
  }
})
</script>

<style lang="less" scoped>
.searchBar{
  padding: 0 24px;
  margin: auto;
  width: 100%;
  min-width: 466px;
  height: 38px;
  text-align: center;
  .suffix{
    float: right;
  }
  .searchInput{
    width: 435px;
    padding-right: 0;
    height: 38px;
    background-color: #e9eef2;
    border-radius: 8px;
    border: 1px #e9eef2 solid;
    ::v-deep .ant-input{
      background-color: #e9eef2;
    }
    .searchBtnDiv{
      transform-origin: center;
      transform: scale(0.85);
      .searchBtn{
        border-radius: 6px;
      }
    }
  }
}
</style>
